import React, { useEffect, useState } from "react";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {UserService} from "../../data/user";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import CreateUser from './Create'; // Import the modal
import UpdateUser from './update'; // Import the modal
import DeleteUser from "./Delete";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";  
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';

import { Button, Table, Space, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const User = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [data, setData] = useState([]);
    const [openUpdate, setOpenUpdate] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
    const { AllUsers } = UserService();

    const showModalCreatePost = () => {
        setIsCreateModalOpen(true);
      };
      const handleModalCloseModalCreate = () => {
        setIsCreateModalOpen(false);
      };
    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
    }, []);

    const fetchData = async () => {
        try {
            const userData = await AllUsers();
            if (userData) {
                setData(userData);
            }
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };
    

    const renderAccessLabel = (role) => {
        return "Admin" ;
    };

    const columns = [
        // { field: "id", headerName: "ID" },
         { field: "userName", headerName: "User Name", flex: 1 },
        // { field: "phone_number", headerName: "Phone Number", flex: 1 },
        { field: "email", headerName: "Email", flex: 1 },
        {
            field: "role",
            headerName: "Role",
            flex: 1,
            renderCell: ({ row: { role } }) => (
                <Box
                    width="40%"
                    p="5px"
                    display="flex"
                    justifyContent="flex-start"
                    marginTop="10px"
                    backgroundColor={
                        role === 1
                            ? colors.greenAccent[600]
                            : role === "manager"
                                ? colors.blue[600]
                                : colors.greenAccent[700]
                    }
                    borderRadius="4px"
                >
                    {role === 1 ? <AdminPanelSettingsOutlinedIcon /> : <LockOpenOutlinedIcon />}
                    <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
                        {renderAccessLabel(role)}
                    </Typography>
                </Box>
            ),
        },
        {
            headerName: "Action",
            renderCell: ({ row }) => (
                <Box display="flex" justifyContent="center">
                    <IconButton color={colors.grey[500]} onClick={() => handleEdit(row)}>
                        <EditNoteOutlinedIcon />
                    </IconButton>
                    <IconButton color={colors.redAccent[500]} onClick={() => handleDelete(row)}>
                        <DeleteOutlinedIcon />
                    </IconButton>
                </Box>
            ),
        }
    ];

    const handleEdit = (row) => {
        setSelectedUser(row);
        setOpenUpdate(true);
    };

    const handleDelete = (row) => {
        setSelectedUser(row);
        setOpenDelete(true);
    };

    const handleUpdateSuccess = () => {
        fetchData(); // Fetch data again after successful update
    };

    return (
        <Box m="20px">
        <Header title="User" subtitle="Managing the User" />
        <IconButton
          color={colors.redAccent[500]}
          onClick={showModalCreatePost}
        >
          <CreateNewFolderIcon />
        </IconButton>
        {data.length > 0 && (
          <DataGrid
            rows={data}
            columns={columns}
            getRowId={(row) => row.id} // Specify postId as the row ID
            initialState={{
              pagination: { 
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[5, 10]}
            rowHeight={110} 
          
          />
        )}
                <CreateUser
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        onUpdateSuccess={handleUpdateSuccess}
      />
            <UpdateUser open={openUpdate} onClose={() => setOpenUpdate(false)} user={selectedUser} onUpdateSuccess={handleUpdateSuccess} />
            <DeleteUser open={openDelete} onClose={() => setOpenDelete(false)} user={selectedUser} onUpdateSuccess={handleUpdateSuccess} />
        </Box>
    );
};

export default User;
