import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDPostingCategories } from "../../data/postingCategories";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreatePostingCategorieModal from "./Create";
import UpdatePostingCategorieModal from "./Update";
import DeletePostingCategorie from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

const PostingCategories = () => {
  const { GetPostingCategories } = CRUDPostingCategories();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [category, setPostingCategorie] = useState(null);
  const [categories, setPostingCategories] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    if (categoryData) {
      setPostingCategories(categoryData);
    }
  };

  const showModalEditPostingCategorie = (row) => {
    console.log("Selected category for edit:", row);
    setPostingCategorie(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setPostingCategorie(null);
  };

  const showModalConfirmDeletePostingCategorie = (row) => {
    setPostingCategorie(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreatePostingCategorie = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  
  const columns = [
    { field: "categoryId", headerName: "ID", flex: 0.3 },
    {
      field: "categoryName",
      headerName: "category Name",
      flex: 0.7,
      editable: true,
    },
    {
      field: "createdTime",
      headerName: "createdTime",
      flex: 1,
      editable: true,
    },
    {
      field: "updatedTime",
      headerName: "updatedTime",
      flex: 1,
      editable: true,
    },

 
    {
      field: "status",
      headerName: "status",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <span>{params.value === 0 ? 'Active' : 'Inactive'}</span>
      ),
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditPostingCategorie(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeletePostingCategorie(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="PostingCategories" subtitle="Managing the PostingCategories" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreatePostingCategorie}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.categoryId} // Specify categorieId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        />
      )}
      <CreatePostingCategorieModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdatePostingCategorieModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeletePostingCategorie
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default PostingCategories;
