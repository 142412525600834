import "./introproduct.css"
import DOMPurify from "dompurify";
import noImage from "../../../image/600x300.png";
import axios from "../../../data/custom/axios";
const IntroPdoduct = ({index, name, title, description, img, link, marketprice, retailprice }) => {
    console.log(marketprice)
    const sanitizeHTML = (html) => {
        return {
          __html: DOMPurify.sanitize(html)
        };
      };

      let imageUrl = null;
  if (!img) {
    imageUrl = noImage;
  } else {
    imageUrl = `${axios.defaults.baseURL}/img/${img}`;
    console.log("img",imageUrl)
  }

  const gia = marketprice;
  let percent = 0;
  if(marketprice = null && retailprice != null ){
  percent = ((marketprice - retailprice) / marketprice) * 100;
  }
  const formatPrice=(price)=>{
    if(price){
    return price.toLocaleString('vi-VN');
    }
    else{
        return price;
    } // Định dạng số theo tiêu chuẩn tiền tệ Việt Nam
}
    return (
        <div className="introProduct">
            <div className="card">
                {/* card left */}
                <div className="product-imgs">
                    <div className="img-display">
                        <div className="img-showcase">
                            <img src={imageUrl} alt="shoe image" />
                        </div>
                    </div>
                </div>
                {/* card right */}
                <div className="product-content">
                    <h2 className="product-title">{name}</h2>
                    <div className="product-price">
                        <p className="last-price">Giá Thị Trường: <span>{formatPrice(gia)}<em style={{fontSize: '15px'}}>đ</em></span></p>
                        <p className="new-price">Giá Bán: <span>{formatPrice(retailprice)}<em style={{fontSize: '15px'}}>đ</em>(-{percent}%)</span></p>
                    </div>
                    <div className="product-detail"
                     dangerouslySetInnerHTML={{ __html: title }}
                    > 
                    </div>
                    {/* <div className="purchase-info">
                        <input type="number" min={0} defaultValue={1} /> 
                        <button type="button" className="btn">
                            Add to Cart <i className="fas fa-shopping-cart" />
                        </button>
                        <button type="button" className="btn">Compare</button>
                    </div> */}
                    {/* <div className="social-links">
                        <p>Share At: </p>
                        <a href="#">
                            <i className="fab fa-facebook-f" />
                        </a>
                        <a href="#">
                            <i className="fab fa-twitter" />
                        </a>
                        <a href="#">
                            <i className="fab fa-instagram" />
                        </a>
                        <a href="#">
                            <i className="fab fa-whatsapp" />
                        </a>
                        <a href="#">
                            <i className="fab fa-pinterest" />
                        </a>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default IntroPdoduct;