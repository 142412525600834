import React, { useState, useEffect } from "react"; 
import "./style/Topnew.css";
import Slider from "react-slick";
import { CRUDPosts } from "../../data/posts";
import { CRUDPostingCategories } from "../../data/postingCategories";
import axios from "../../data/custom/axios";
const showImage = (url) => {
  return (`${axios.defaults.baseURL}/img/${url}`)
}

const TopnewCard =({index, id, imageUrl, title}) =>{
return(
  <div key={index} className="col-lg-11 wow fadeIn" data-wow-delay={`${0.3 + index * 0.2}s`}>
  <div className="case-item position-relative overflow-hidden rounded mb-2">
    <img className="img-fluid" src={showImage(imageUrl)} alt={title} />
    <a className="case-overlay" href={`/tin_tuc/${id}`}>
      <small>tin tức</small>
      <h3 className="mb-3">
        {title}
      </h3>
    </a>
  </div>
</div>
);
}

function TopNews() {
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);


  const fetchData = async () => {
    try {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      if (categoryData) {
        const categoriNews = categoryData.find(ce => ce.categoryName.toLowerCase() === "Tin Tức".toLowerCase());
        if (categoriNews) {
          const filterPost = postData.filter(po => po.categoryId === categoriNews.categoryId);
          if (filterPost) {
            // Sort posts by date (assuming there's a date field) and take top 3
            const sortedPosts = filterPost.sort((a, b) => new Date(b.date) - new Date(a.date)).slice(0, 5);
            setPosts(sortedPosts);
            console.log("post",posts)
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };


  if (loading) {
    return <div className="load">
      <p className="loader"></p>
    </div>;
  }

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: "ease",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <div className="container-fluid ">
        <div className="container new">
          <div className="mx-auto wow fadeIn" data-wow-delay="0.1s"> 
            <div className="row">
              <h2 className="col align-self-start mb-2">TIN TỨC NỔI BẬT</h2>
              <a href="/tin_tuc" className="col-auto btn btn-orange align-self-end">Xem Thêm</a>
            </div>    
          </div>
          <div className="row g-4">
            <Slider {...settings}>
            {posts && posts.map((post, index) => (
              
             < TopnewCard 
             index= {index}
             id={post.postId}
             imageUrl={post.imageUrl}
            title={post.title}
            />
            ))}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
}      

export default TopNews;