import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import {CRUDNavlist} from "../../data/navlist";
import { times } from "lodash";
const { Option } = Select;

const CreateNavlistModal = (props) => {
  const { open, onClose, onUpdateSuccess } = props;
  const {CreateNavlist} = CRUDNavlist();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);


  const now = new Date();

    const handleOk = async () => {
      try {
        const values = await form.validateFields();
        setConfirmLoading(true);
       
        const AddNavlist = {
          // Id: values.categoryId,  
          NavlistTitle: values.NavlistTitle,
          NavlistUrl: values.NavlistUrl,
        };
      
        
        const result = await CreateNavlist(AddNavlist);
       
        if (result) {
          message.success('Navlist create successfully');
          onUpdateSuccess();
          onClose();
        } else {
          message.error('Failed to createNavlist');
        }
      } catch (error) {
        console.error('Validation failed:', error);
      } finally {
        setConfirmLoading(false);
      }
    }
  

  return (
   <Modal
       width="55%"
     title="Create newNavlist"
      visible={open}
      onOk={handleOk}
      onCancel={onClose}
    >
     <Form form={form} layout="vertical">

        <Form.Item
          name="NavlistTitle"
          label="NavlistTitle"
          rules={[{ message: 'Please input theNavlist name!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
        <Form.Item
          name="NavlistUrl"
          label="NavlistUrl"
        >
          <Input  />  
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNavlistModal;
