import React, { useEffect, useRef, useState } from 'react';
import "../assets/slide.css"
import 'animate.css'; 
import { CRUDPosts} from "../data/posts";
import {CRUDPostingCategories} from "../data/postingCategories"
import axios from "../data/custom/axios";
import img from "../image/slide1.jpg";
import img2 from "../image/slide2.jpg";

const Slide = () => {
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [slides, setSlides] = useState([]);
  const [base, baseImage] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      console.log("data",categoryData,postData);
      if (categoryData && postData) {
        const slideCategory = categoryData.find(c => c.categoryName === "Slide");
        console.log("slideCategory",slideCategory);
        if (slideCategory) {
          const filteredPosts = postData.filter(post => Number(post.categoryId) === Number(slideCategory.categoryId));
          setSlides(filteredPosts);
          console.log("filteredPosts",filteredPosts);
        }
      }
    };

    fetchData();
  }, []);

  const carouselRef = useRef(null);
  const movingBoxRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const handleSlide = (event) => {
      const nextIndex = event.to;
      setCurrentIndex(nextIndex);
      const direction = event.direction === 'left' ? '-100%' : '100%';
      movingBoxRef.current.style.transform = `translateX(${direction})`;
      setTimeout(() => {
        movingBoxRef.current.style.transition = 'transform 0s';
        movingBoxRef.current.style.transform = 'translateY(0)';
        setTimeout(() => {
          movingBoxRef.current.style.transition = 'transform 1s ease';
        }, 50);
      }, 500);
    };

    const carouselElement = carouselRef.current;
    if (carouselElement) {
      carouselElement.addEventListener('slide.bs.carousel', handleSlide);
      return () => {
        carouselElement.removeEventListener('slide.bs.carousel', handleSlide);
      };
    }
  }, []);

const showImage = (url) => {
  return (`${axios.defaults.baseURL}/img/${url}`)
}
  return (
    <>
      <div id="carouselcustom" className="carousel" ref={carouselRef} data-bs-ride="carousel" data-bs-interval={4000}>
        <div className="box1">
          <div className="moving-box" ref={movingBoxRef}>
            {slides.map((item, index) => (
              <div key={index} className={`contentx text-center animate__fadeInDown ${currentIndex === index ? 'd-block' : 'd-none'}`}>
                <div className="btn btn-sm border rounded-pill px-3 mb-3 animated">KCL GROUP</div>
                <h2 className="display-4 mb-4 animated strim-line-3 m-b-20 ">{item.title}</h2>
                <p className="mb-4 animated">{item.introContent}</p>
                <a href={`/tin_tuc/${item.postId}`} className="btn btn-orange py-sm-3 px-sm-5 rounded-pill animated">Xem Thêm</a>
              </div>
            ))}
          </div>
        </div>
        <div className="carousel-indicators">
          {slides.map((_, index) => (
            <button key={index} type="button" data-bs-target="#carouselcustom" data-bs-slide-to={index} className={index === 0 ? "active" : ""} aria-current={index === 0 ? "true" : "false"} aria-label={`Slide ${index + 1}`} />
          ))}
        </div>
        <div className="carousel-inner">
          {slides.map((item, index) => (
            <div key={index} className={`carousel-item ${index === 0 ? "active" : ""}`}>
              <div className='slide-image animated'>
                <a href={`/tin_tuc/${item.postId}`}>
                  <img className="img-fluid" src={item.imageUrl ? showImage(item.imageUrl) : img} alt={item.title} />
                </a>
              </div>
            </div>
          ))}
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselcustom" data-bs-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true" />
          <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#carouselcustom" data-bs-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true" />
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <div className="box2 bg-2">
        <div className="moving-box" ref={movingBoxRef}>
          {slides.map((item, index) => (
            <div key={index} className={`contentx animate__fadeInLeft ${currentIndex === index ? 'd-block' : 'd-none'}`}>
              <h2 className="display-4 animated">{item.title}</h2>
              <p className="mb-4 animated">{item.introContent}</p>
              <a href={`/tin_tuc/${item.postId}`} className="btn btn-orange animated">Xem Thêm</a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Slide;
