import React, { useEffect, useState } from 'react';
import Contencard from '../components/item/contenCard';
import Banner from "../components/child/banner";
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import {
  HelmetHead
  } from "../components";

const Network=()=>{
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [Post, setPosts] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    const postData = await GetPosts();
    if (categoryData) {
      const categoriHistori = categoryData.find(ce => ce.categoryName.toLowerCase() === "MẠNG LƯỚI HOẠT ĐỘNG".toLowerCase());
      if (categoriHistori) {
        const filterPost = postData.filter(po => po.categoryId === categoriHistori.categoryId);
        if (filterPost) {
          setPosts(filterPost);
        }
      }
    }
  };

  return(
<>
<HelmetHead name="MẠNG LƯỚI HOẠT ĐỘNG" title="SỨ MỆNH CỦA KCL GROUP" description="MẠNG LƯỚI HOẠT ĐỘNG"  />
<Banner title ="MẠNG LƯỚI HOẠT ĐỘNG"/>
        
            <div className="container">
                {Post ?( Post.map((item, index)=>( 
                <Contencard
                key={index}
                index={index}
                name={item.title}
                title={item.introContent}
                content = {item.content}
                link={`/tin_tuc/${item.postId}`}
                img={item.imageUrl}
                />
                ))):(
                  <div className="load">
                    <p className="loader"></p>
                  </div>
                )}
            </div>
</>
    )
};

export default Network