import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import {  CRUDProducts } from "../../data/products";
import {useParams} from 'react-router-dom'
const Deleteproduct = ({ open, onClose, Product, onUpdateSuccess }) => {
  
    const {DeleteProductById} =  CRUDProducts();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const id = Product?.id;  
  useEffect(() => {
    // if (category) {
    //   console.log("categorydelete", id);
    // }
  })

    

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await DeleteProductById(id);
      message.success('product deleted successfully');
      setConfirmLoading(false);
      onClose();
      onUpdateSuccess();
    } catch (error) {
    console.log("lỗi",error);
      message.error('Failed to delete product');
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete product ${Product?.name}`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to delete product "{Product?.name}"?</p>
    </Modal>
  );
};

export default Deleteproduct;
