import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDNav } from "../../data/nav";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateNavModal from "./Create";
import UpdateNavModal from "./Update";
import DeleteNav from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

const Navs = () => {
  const { GetNav } = CRUDNav();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [nav, setNav] = useState(null);
  const [categories, setNavs] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const navData = await GetNav();
    if (navData) {
      setNavs(navData);
    }
  };

  const showModalEditNav = (row) => {
    console.log("Selected nav for edit:", row);
    setNav(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setNav(null);
  };

  const showModalConfirmDeleteNav = (row) => {
    setNav(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateNav = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  
  const columns = [
    { field: "navId", headerName: "ID", flex: 0.3 },
    {
      field: "navTitle",
      headerName: "Navigation Name ",
      flex: 0.7,
      editable: true,
    },
    {
      field: "navUrl",
      headerName: "Đường Dẫn",
      flex: 1,
      editable: true,
    },


    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditNav(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteNav(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Navs" subtitle="Managing the Navs" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateNav}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.navId} // Specify categorieId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      )}
      <CreateNavModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        nav={nav}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdateNavModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        nav={nav}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeleteNav
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        nav={nav}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default Navs;
