import React, { useEffect, useState } from 'react';

import Contencard from '../components/item/contenCard';
import Banner from "../components/child/banner";
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import ImgBanner from "../image/banner/mission-vision-banner.jpg"
import SodoImg from "../image/sodotochuc.png"
import {
  HelmetHead
  } from "../components";

const  trachnhiem = [
    {
      "name": "SƠ ĐỒ TỔ CHỨC CỦA KCL GROUP",
      "title": `KCL Group tự hào là một trong những tổ chức hàng đầu trong lĩnh vực của mình, với một cơ cấu tổ chức chặt chẽ và hiệu quả. Chúng tôi xây dựng một hệ thống hoạt động mang tính toàn cầu, tập trung vào việc cung cấp các giải pháp và dịch vụ đa dạng, từ sản xuất đến phân phối và các dịch vụ hỗ trợ sau bán hàng.

Với mỗi thành viên trong KCL Group là một phần không thể thiếu của sự phát triển, chúng tôi cam kết tạo dựng một môi trường làm việc khuyến khích sáng tạo và phát triển cá nhân. Sự hài lòng của khách hàng luôn là ưu tiên hàng đầu, và chúng tôi luôn đặt mục tiêu cao nhất trong việc cung cấp giải pháp và sản phẩm với chất lượng tối ưu nhất.`,
      "link": "/noidung",
      "img": SodoImg,
      "date": "21/8/2023"
  }]
const Organizational=()=>{
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [Post, setPosts] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    const postData = await GetPosts();
    if (categoryData) {
      const categoriHistori = categoryData.find(ce => ce.categoryName.toLowerCase() === "MẠNG LƯỚI HOẠT ĐỘNG".toLowerCase());
      if (categoriHistori) {
        const filterPost = postData.filter(po => po.categoryId === categoriHistori.categoryId);
        if (filterPost) {
          setPosts(filterPost);
          console.log("Post Data:", filterPost);
        }
      }
    }
  };
  if (!Post) {
    return <div className="load">
      <p className="loader"></p>
    </div>;
  }
  
  
    return(
<>
<HelmetHead name="SƠ ĐỒ TỔ CHỨC" title="SƠ ĐỒ TỔ CHỨC" description="SƠ ĐỒ TỔ CHỨC"  img={ImgBanner} />
<Banner title ="SƠ ĐỒ TỔ CHỨC"/>
            <div className="container">
            {Post && Post.map((item, index)=>( 
                <Contencard
                key={index}
                index={index}
                name={item.title}
                title={item.introContent}
                content = {item.content}
                link={`/tin_tuc/${item.postId}`}
                img={item.imageUrl}
                />
                ))}
            </div>
           
    </>

    )
};

export default Organizational