import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import {CRUDNav} from "../../data/nav";
import { times } from "lodash";
const { Option } = Select;

const CreateNavModal = (props) => {
  const { open, onClose, onUpdateSuccess } = props;
  const {CreateNav} = CRUDNav();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);


  const now = new Date();

    const handleOk = async () => {
      try {
        const values = await form.validateFields();
        setConfirmLoading(true);
       
        const AddNav = {
          // Id: values.categoryId,  
          navTitle: values.navTitle,
          navUrl: values.navUrl,
        };
      
        
        const result = await CreateNav(AddNav);
       
        if (result) {
          message.success('Nav create successfully');
        
          onUpdateSuccess();
          onClose();
        } else {
          message.error('Failed to createNav');
        }
      } catch (error) {
        console.error('Validation failed:', error);
      } finally {
        setConfirmLoading(false);
      }
    }
  return (
   <Modal
       width="55%"
     title="Create newNav"
      visible={open}
      onOk={handleOk}
      onCancel={onClose}
    >
     <Form form={form} layout="vertical">

        <Form.Item  className="uppercase-input"
          name="navTitle"
          label="navTitle"
          rules={[{ message: 'Please input theNav name!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
        <Form.Item
          name="navUrl"
          label="navUrl"
        >
          <Input  />  
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateNavModal;
