import axios from "../custom/axios";
import axiosInstance from "../custom/axios";
import {message} from "antd";
const UserService = () => {
  const AllUsers = async () => {
    try {
      const res = await axiosInstance.get('/account'  );
      console.log(res);
      return res;
     
    } catch (error) {
      console.error('Error during fetching users:', error);
   if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      return false;
    }
  };

const CreateUser = async (userData) => {
  console.log("user dâta", userData)
  try {
    const res = await axiosInstance.post('/account/register', userData);
    console.log(res);
    return true;
  } catch (error) {
    console.error('Error updating user:', error);
    if (error.response && error.response.name === 403 ) {
      message.error('Bạn không có quyền thêm danh mục này.');
    } else if (error.response && error.response.status === 400   || error.response.status === 500) {
      message.error('Lỗi Hệ thống. Vui lòng thử lại sau.');
     } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
      message.error('Vui Lòng Đăng Nhập lại.');
     }
    throw error;
  }
}

  const userId = async (id) => {
    try {
      const res = await axios.get(`/User${id}`);
      return res;
      console.log(res);
    } catch (error) {
      console.error('Error during fetching users:', error);
      return false;
    }
  };

  const UpdateUser = async (userId, userData) => {
    try {
      const res = await axios.put(`/Users/${userId}`, userData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      console.log(res);
      return true;
    } catch (error) {
      console.error('Error updating user:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền cập nhật danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error;
    }
  };
  

  const DeleteUser = async (id) => {
    try {
      // eslint-disable-next-line
      const res = await axios.delete(`/account/Delete${id}`);
      return true;
    } catch (error) {
      console.error("Error deleting user", error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền xóa danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error;
    }
  };

  return { AllUsers, UpdateUser, DeleteUser, CreateUser };
}

export {UserService};
