import axios from "./custom/axios";

const CRUDProductCategorys = () => {
  const GetProductCategorys = async () => {
    try {
      const response = await axios.get(`/ProductCategory`);
      console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categorys:", error);
      return false;
    }
  };
  const CreateProductCategory=async (formData) => {
    try {
      const res = await axios.post(`/ProductCategory`,formData)
      console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      throw error; 
    }
  };

  const UpdateProductCategory = async (formData) => {
    try {
      const res = await axios.put(`/ProductCategory/${formData.id}`, formData);
      console.log(res.data);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      throw error; 
    }
  };
  const DeleteProductCategoryById = async (id) =>{
    try {
      const res = await axios.delete(`/admin/category/delete/${id}`);
      console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete category",error);
      throw error;
    };
  }
  return { GetProductCategorys, UpdateProductCategory, DeleteProductCategoryById, CreateProductCategory};
}


export {CRUDProductCategorys};
