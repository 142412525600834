import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import './Footer.css';

function Footer() {
  return (
    <MDBFooter bgColor='light' className='text-lg-start text-muted'>
      <section className='d-flex justify-content-left justify-content-lg-left p-4 border-bottom'>
        <div className='me-3 d-none d-lg-block'>
          <p >Theo dõi chúng tôi:</p>
        </div>
        <div>
          <a href="https://www.facebook.com/kclhealthcare" className="me-4">
            <i className="fa-brands fa-facebook" style={{ color: '#0011ff' }}></i>
          </a>
          <a href="https://kclgroup.vn/" className="me-4">
            <i className="fa-brands fa-google" style={{ color: '#0068b7' }}></i>
          </a>
          <a href="https://www.instagram.com/kclhealthcare/" className="me-4">
            <i className="fa-brands fa-square-instagram" style={{ color: '#fd941c' }}></i>
          </a>
          <a href="https://www.tiktok.com/@kclhealthcare" className="me-4">
            <i className="fa-brands fa-tiktok"></i>
          </a>
        </div>
      </section>

      <section className='lienhe'>
        <MDBContainer className=' text-md-start mt-5 mb-4'>
          <MDBRow className='mt-6'>

            <MDBCol md='4' lg='3' xl='3' className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>LIÊN HỆ VỚI CHÚNG TÔI</h6>
              <p>
                <a href="https://maps.app.goo.gl/TLQGZjAghkAeDaKu9" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <MDBIcon color='secondary' icon='home' className='me-2' />
                  160 Lê Đình Lý, Hòa Thuận Đông, Hải Châu, Đà Nẵng, Việt Nam
                </a>
              </p>
              <p>
                <a href="mailto:kclvn.solution@gmail.com" className="me-3" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <MDBIcon color='secondary' icon='envelope' className='me-3' /> kclvn.solution@gmail.com
                </a>
              </p>
              <p>
                <a href="tel:0914911577" className="me-3" style={{ textDecoration: 'none', color: 'inherit' }}>
                  <MDBIcon color="secondary" icon="phone" className="me-3" /> 091 491 15 77
                </a>
              </p>
            </MDBCol>
      
            <MDBCol className=' mb-12'>
              <div className="mappar">
                <iframe 
                className="maprouter" 
                loading="lazy"
                allowfullscreen
                referrerpolicy="no-referrer-when-downgrade"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d958.5604603215378!2d108
                .2099999696206!3d16.052935287849763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219b9ce0625c7%3A0xbf6
                3be46a3f58014!2zMTYwIEzDqiDEkMOsbmggTMO9LCBIw7JhIFRodeG6rW4gxJDDtG5nLCBI4bqjaSBDaMOidSwgxJDDoCBO4bq1bmcgNTUwMDAw
                LCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1721615787845!5m2!1svi!2s"
                  />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>

      <div className='text-center p-4' style={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}>
        Copyright © :
        <a className=' fw-bold' href='https://kclgroup.com/'>
          KCL Group
        </a>
      </div>
    </MDBFooter>
  );
}

export default Footer;
