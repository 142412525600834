import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./animate/animate.min.css"


ReactDOM.createRoot(document.getElementById('root')).render(
 
<React.StrictMode>
  <App/>
</React.StrictMode>
)

