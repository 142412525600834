import React, { useState } from "react";
import { Modal, Input, Form, message, Select, Button } from 'antd';
import { SketchPicker } from 'react-color';
import { CRUDCol } from "../../data/color";

const { Option } = Select;

const CreateColModal = (props) => {
  const { open, onClose, onUpdateSuccess } = props;
  const { CreateCol } = CRUDCol();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [color, setColor] = useState('#fc7321'); // Default color

  const handleColorChange = (color) => {
    setColor(color.hex);
    // Update color state with the selected color
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
      
      const AddCol = {
        colorName: values.colorName,
        colorCode: color, // Use the color state
      };
    
      const result = await CreateCol(AddCol);
    
      if (result) {
        message.success('Col created successfully');
        onUpdateSuccess();
        onClose();
      } else {
        message.error('Failed to create Col');
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };


  return (
    <Modal
      width="55%"
      title="Create new Col"
      visible={open}
      onOk={handleOk}
      onCancel={onClose}
      confirmLoading={confirmLoading}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          className="uppercase-input"
          name="colorName"
          label="Color Name"
          rules={[{ required: true, message: 'Please input the color name!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
        <Form.Item
          name="codeColor"
          label={color}
        >
          <Input value={color} readOnly style={{backgroundColor: color}} />  
        </Form.Item>
        <Form.Item
          label="Select Color"
        >
          <SketchPicker
            color={color}
            onChangeComplete={handleColorChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateColModal;
