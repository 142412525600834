// import React from "react";

import React, { useState, useEffect } from 'react';

import Contencard from '../components/item/contenCard';
import Banner from "../components/child/banner";
import bannerImg from '../image/banner-tuyen-dung.jpg';
import TuyendungImg from "../image/tuyen-dung.jpg"
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import {
  HelmetHead
  } from "../components";

// const  trachnhiem = [
//     {
//       "name": "Chính sách phát triển nhân viên",
//       "title": `Với sự nỗ lực của Ban Nhân sự cùng các công ty thành viên, các đơn vị có nguồn lực chủ chốt thuộc lĩnh vực công nghệ thông tin, hàng loạt chính sách nhân sự được điều chỉnh trong năm 2024. Nhờ đó, KCL vừa được vinh danh thương hiệu Nhà tuyển dụng hấp dẫn nhất trong lĩnh vực IT theo khảo sát của Tổ chức tư vấn châu Á về nguồn nhân lực chất lượng hàng đầu từ các trường đại học lớn toàn quốc.`,
//       "link": "/noidung",
//       "img": TuyendungImg,
//       "date": "21/8/2023"
//   }]

  
// const  tuyendung = [
//     {
//       "name": "Quy trình tuyển dụng của KCL Group",
//       "title": `Bước 1: Tiếp nhận và sàng lọc hồ sơ
//       Bước 2: Làm bài test
//       Bước 3: Phỏng vấn
//       Bước 4: Thỏa thuận hợp đồng
//       Bước 5: Hoàn thiện hồ sơ 
//       Bước 6: Ký hợp đồng thử việc`,
//       "img": TuyendungImg,
//       "date": "21/8/2023"
//   }]

function Profession () {

  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [Post, setPosts] = useState(null);
  const [PostPolicy, setPolicy] = useState(null);
  const [PostRecruitment, setRecruitment] = useState(null)

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    const postData = await GetPosts();
    console.log("cateData", categoryData);
    if (categoryData) {
      const categoriPolicy = categoryData.find(ce => ce.categoryName.toLowerCase().includes("chính sách"));
      console.log("categoriPolicy", categoriPolicy);
      const categoriTuyendung = categoryData.find(ce => ce.categoryName.toLowerCase().includes("quy trình"));
      if (categoriPolicy) {
        const filterPost = postData.filter(po => po.categoryId === categoriPolicy.categoryId);
        if (filterPost) {
          setPolicy(filterPost);
        }
        const filterRecruitment = postData.filter(po => po.categoryId === categoriTuyendung.categoryId);
        if (filterRecruitment) {
          setRecruitment(filterRecruitment);
        }
        if (filterPost) {
          setPosts(filterPost);
          console.log(filterPost)
        }
      }
    }
  };
  if (!Post) {
    return <div className="load">
      <p className="loader"></p>
    </div>;
  }

 return (
    <>
    <HelmetHead name="TUYỂN DỤNG" title="CƠ HỘI VIỆC LÀM" description="VIỆC LÀM" />
    <Banner title ="CƠ HỘI VIỆC LÀM" />
    <div>
                <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                     data-bs-target="#home-tab-pane" type="button" role="tab" 
                     aria-controls="home-tab-pane" aria-selected="true">CHÍNH SÁCH TUYỂN DỤNG</button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="profile-tab" data-bs-toggle="tab" 
                    data-bs-target="#profile-tab-pane" type="button" role="tab" 
                    aria-controls="profile-tab-pane" aria-selected="false">QUY TRÌNH TRUYỂN DỤNG</button>
                </li>
                </ul>
                <div className="tab-content" id="myTabContent">
    
                <div className="tab-pane  active " id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                <div className="container">
                    {PostPolicy && PostPolicy.map((item, index)=>( 
                    <Contencard
                    key={index}
                    index={index}
                    name={item.title}
                    title={item.introContent}
                    content = {item.content}
                    link={`/tin_tuc/${item.postId}`}
                    img={item.imageUrl}
                    />
                    ))}
                
                </div>
               
                </div>
    
                <div className="tab-pane fade " id="profile-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
                <div className="container mission">
                    <div className="row justify-content-between m-x-0 p-x-0 field-description">
                    <div className="container">
                    {PostRecruitment && PostRecruitment.map((item, index)=>( 
                    <Contencard
                    key={index}
                    index={index}
                    name={item.title}
                    title={item.introContent}
                    content = {item.content}
                    link={`/tin_tuc/${item.postId}`}
                    img={item.imageUrl}
                    />
                    ))}
                
                </div>
                        </div>
                </div>
               
                </div>
                </div>
            </div>
    </>
 )
}
export default Profession ;
