import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDProducts } from "../../data/products";
import { ManageImage } from "../../data/mutiImage";
import { useParams } from 'react-router-dom'
import { IconButton, useTheme } from "@mui/material";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import axios from "axios";
import "../../custom/mutationObserver";
import UploadImg from "../UploadImage/UploadImg";
import "../../custom/mutationObserver";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const { Option } = Select;
const CreateproductModal = (props) => {
  const { id } = useParams();
  const fileInputRef = useRef(null);
  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, onUpdateSuccess, Productcategory } = props;
  const { CreateProduct } = CRUDProducts();
  const {HandleImageUploadBefore} = ManageImage();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isEditDrawerOpen, setIsDrawerOpen] = useState(false);
  const [content, setContent] = useState('');
  const now = new Date();
  useEffect(() => {
    const now = new Date();
    form.setFieldsValue({
      productDate: now.toISOString().slice(0, 16), // Format the datetime-local value
      updatedDate: now.toISOString().slice(0, 16),
      categoryId: Number(id),
      status : 0
    });
  }, [now, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('Name', values.name);
      formData.append('IntroContent', values.IntroContent);
      formData.append('Description', content);
      const imageFile = form.getFieldValue('image');
      if (imageFile) { 
if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('Image', imageFile);
      }
      formData.append('ulrImage', values.imageUrl); // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('AddedTime', values.productDate);
      formData.append('CategoryId', values.categoryId);
      formData.append('UpdatedDate', values.updatedDate);
      formData.append('Status', values.status);
      if (values.retailprice !== undefined && values.retailprice !== null) {
        formData.append('retailprice', values.retailprice);
      }
     
      if (values.marketprice !== undefined && values.marketprice !== null) {
        formData.append('marketprice', values.marketprice);
      }
     

      // for (let [key, value] of formData.entries()) {
      //   console.log("không biết viết gì", key, value);
      // }
      // console.log("nội dung trong formdata", [...formData]);
      await CreateProduct(formData);
      message.success('product created successfully');
      form.resetFields(); // Reset fields after successful submission
      setContent(''); // Reset nội dung editor
      setImagePreview(null)
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
      onClose();
      onUpdateSuccess();
    } catch (error) {
      message.error('Failed to create product. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };


  //upload image
  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('files', file);
  
    try {
      const response = await axios.post(`http://localhost:5256/api/MutilImg/PostImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Server response:', response.data);
  
      if (response.data && response.data.fileNames && response.data.fileNames.length > 0) {
        const imageUrl = `http://localhost:5256/api/img/${response.data.fileNames[0]}`;
        console.log('Image URL:', imageUrl);
  
        // Sử dụng uploadHandler để chèn URL của ảnh vào nội dung của SunEditor
        uploadHandler({
          result: [{ url: imageUrl }] // Chèn URL của ảnh vào editor
        });
      } else {
        throw new Error('No file names returned from the server.');
      }
    } catch (error) {
      console.error('Image upload failed:', error);
      message.error('Failed to upload image. Please try again later.');
      uploadHandler({
        result: [{ error: 'Failed to upload image' }] // Trả về lỗi nếu upload thất bại
      });
    }
  
    // Trả về false để chặn việc chèn ảnh base64 vào SunEditor
    return false;
  };

  const handleEditorChange = (content) => {
    setContent(content); // Capture the editor content
  };
  //end upload img qill


  const showImglist = () => {
    setIsDrawerOpen(true);
  };

  const closeImglist = () => {
    setIsDrawerOpen(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setContent(''); // Xóa nội dung của SunEditor khi nhấn Cancel
    onClose();
  };

  return (
   <Modal
       width="55%"
     title="Create new product"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
     onCancel={handleCancel} 
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the product title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="IntroContent"
          label="Giới thiệu"
        >
          <Input.TextArea rows={9}/>
        </Form.Item>
        <Form.Item
          name="content"
          label="Nội dung chính"
        >
          <IconButton
            onClick={showImglist}
          >
            <PermMediaIcon />
          </IconButton>
              <SunEditor
            setContents={content}
            onImageUploadBefore={handleImageUploadBefore} // Chặn hành vi mặc định trước khi upload
            onChange={handleEditorChange}
            setOptions={{
              buttonList: [
                ['undo', 'redo'],
                ['bold', 'underline', 'italic', 'strike'],
                ['list', 'indent', 'outdent'],
                ['align', 'fontSize', 'formatBlock'],
                ['image', 'link'],
                ['removeFormat']
              ],
            }}
          />
        </Form.Item>
        <input
          type="file"
          accept="image/*"
          ref={fileInputRef}
          onChange={(e) => {
            const file = e.target.files[0];
            form.setFieldsValue({ image: file });

            // Tạo URL để preview ảnh
            if (file) {
              const previewURL = URL.createObjectURL(file);
              setImagePreview(previewURL);
            } else {
              setImagePreview(null);
            }
          }}
        />
        {imagePreview && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
        <Form.Item
          name="imageUrl"
          label="Image URL"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="productDate"
          label="product Date"
          rules={[{ required: true, message: 'Please input the product date!' }]}
        >
          <Input type="datetime-local" />
        </Form.Item>

        <Form.Item
          name="categoryId"
          label="Loại Bài Viết"
          rules={[{ required: true, message: 'Please select the category ID!' }]}
        >
          <Select  >
            {Productcategory && Productcategory.length > 0 ? (
              Productcategory.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))
            ) : (
              <Option value="" disabled>
                No categories available
              </Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="updatedDate"
          label="Updated Date"
          rules={[{ required: true, message: 'Please input the updated date!' }]}
        >
          <Input type="datetime-local" disabled />
        </Form.Item>
        <Form.Item
          name="retailprice"
          label="Giá Bán"
        >
          <Input.TextArea/>
          </Form.Item>
          <Form.Item
          name="marketprice"
          label="Giá Thị Trường"
        >
          <Input.TextArea/>
          </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
          <Select>
            <Option value={0}>active</Option>
            <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
      <UploadImg
        open={isEditDrawerOpen}
        onClose={closeImglist}
      />  
    </Modal>
  );
};
export default CreateproductModal;
