import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../KCL-logo.svg';
import './Navigation.css';

const Navigation = () => {
  const handleDropdownClick = (e) => {
    e.preventDefault();
    e.currentTarget.classList.toggle('show');
    const dropdownMenu = e.currentTarget.querySelector('.dropdown-menu');
    dropdownMenu.classList.toggle('show');
  };

  return (
    <div className="container-fluid header">
      <div className="container">
        <nav className="navbar navbar-expand-lg navbar p-0">
          <div className="navbar-brand">
            <a href="/">
              <img src={Logo} alt="" />
            </a>
          </div>
          <button
            type="button"
            className="navbar-toggler ms-auto me-0"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav">
              <div className="nav-item dropdown" onClick={handleDropdownClick}>
                <NavLink to="/ve_kcl" className="nav-link dropdown-toggle">
                  Về KCL
                </NavLink>
                <div className="dropdown-menu mt-2">
                  <NavLink to="/ve_kcl/lich_su" className="dropdown-item" activeClassName="active">
                    Lịch Sử
                  </NavLink>
                  <NavLink to="/ve_kcl/tam_nhin_chien_luoc" className="dropdown-item" activeClassName="active">
                    Tầm Nhìn Chiến Lược
                  </NavLink>
                  <NavLink to="/ve_kcl/su_menh" className="dropdown-item" activeClassName="active">
                    Sứ Mệnh
                  </NavLink>
                  <NavLink to="/ve_kcl/gia_tri_cot_loi" className="dropdown-item" activeClassName="active">
                    Giá Trị Cốt Lõi
                  </NavLink>
                  <NavLink to="/ve_kcl/mang_luoi_hoat_dong" className="dropdown-item" activeClassName="active">
                    Mạng Lưới Hoạt Động
                  </NavLink>
                  <NavLink to="/ve_kcl/So_do_to_chuc" className="dropdown-item" activeClassName="active">
                    Sơ Đồ Tổ Chức
                  </NavLink>
                </div>
              </div>

              <div className="nav-item dropdown" onClick={handleDropdownClick}>
                <NavLink to="/san_pham" className="nav-link dropdown-toggle">
                  Sản phẩm - Dịch vụ
                </NavLink>
                <div className="dropdown-menu mt-2">
                  <NavLink to="/san_pham/k_solution" className="dropdown-item" activeClassName="active">
                    K SOLUTION
                  </NavLink>
                  <NavLink to="/san_pham/k_healthtech" className="dropdown-item" activeClassName="active">
                    K HEALTHTECH
                  </NavLink>
                  <NavLink to="/san_pham/k_technical" className="dropdown-item" activeClassName="active">
                    K TECHNICAL
                  </NavLink>
                  <NavLink to="/san_pham/thiet_bi" className="dropdown-item" activeClassName="active">
                    PHỤ KIỆN
                  </NavLink>
                </div>
              </div>

              <NavLink to="/doi_tac" className="nav-link nav-item ">
                ĐỐI TÁC - KHÁCH HÀNG
              </NavLink>
              <NavLink to="/tin_tuc" className="nav-link nav-item ">
                Tin Tức
              </NavLink>
              <NavLink to="/Viec-lam" className="nav-link nav-item ">
                CƠ HỘI NGHỀ NGHIỆP
              </NavLink>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Navigation;
