import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { CRUDBanners } from "../../data/banner";
import Box from "@mui/material/Box";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateBannerModal from "./Create";
import UpdateBannerModal from "./Update";
import DeleteBanner from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ImagePreview from "../../components/imagePreview";




const Banners = () => {
  const { GetBanners } = CRUDBanners();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Banner, setBanner] = useState(null);
  const [categories, setBanners] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const BannerData = await GetBanners();
    if (BannerData) {
      setBanners(BannerData);
    }
  };

  const showModalEditBanner = (row) => {
    console.log("Selected Banner for edit:", row); 
    setBanner(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setBanner(null);
  };

  const showModalConfirmDeleteBanner = (row) => {
    setBanner(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };
  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateBanner = () => {
    setIsCreateModalOpen(true);
  };
  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  const columns = [
    { field: "bannerId", headerName: "ID", flex: 0.3 },
    {
      field: "bannerName",
      headerName: "Banner Name",
      flex: 0.3,
      editable: true,
    },
    {
      field: "imageData",
      headerName: "Image Data",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div  style={{ maxWidth: '100px', maxHeight: '100px', padding: '10px',  }} >
         <ImagePreview image={params.value}   />
         </div>
    ),
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditBanner(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteBanner(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Banners" subtitle="Managing the Banners" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateBanner}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.bannerId} // Specify bannerId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
          rowHeight={110} 
        />
      )}
      <CreateBannerModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        Banner={Banner}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdateBannerModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        Banner={Banner}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeleteBanner
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        Banner={Banner}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default Banners;
