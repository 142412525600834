import React, { useEffect, useState, useRef, useCallback } from 'react';
import './Timeline.css';
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import Banner from '../components/child/banner.jsx';
import a3 from "../image/banner3.jpg";
import Card from "../components/child/SanPham";


import { HelmetHead } from "../components";

const TIMELINE_VALUES = {
  start: 150,
  step: -10
};

function History() {
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [Post, setPost] = useState(null);
  const [stickyTop, setStickyTop] = useState(0);
  const [scrollTarget, setScrollTarget] = useState(false);
  const [milestonePaddings, setMilestonePaddings] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const timelineRef = useRef(null);

  const [scrollPosition, setScrollPosition] = useState(0);

  
  // useEffect(() => {

  //   const handleScroll = () => {
  //     setScrollPosition(window.scrollY);
  //     console.log('Scroll Position:', window.scrollY);
  //   };
  
  //   console.log('Component mounted');

  //   // Thêm sự kiện cuộn
  //   window.addEventListener('scroll', handleScroll);

  //   // Dọn dẹp sự kiện khi component unmount
  //   return () => {
  //     console.log('Component unmounted');
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []); // Dependency array trống

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    const postData = await GetPosts();
    if (categoryData) {
      const categoriHistori = categoryData.find(ce => ce.categoryName.toLowerCase() === "Lịch Sử".toLowerCase());
      if (categoriHistori) {
        const postHistori = postData.filter(po => po.categoryId === categoriHistori.categoryId).sort((a, b) => a.year - b.year);
        if (postHistori) {
          setPost(postHistori);
        }
      }
    }
  };

  useEffect(() => {
    // Calculate padding-bottom dynamically

    const milestones = document.querySelectorAll('.milestone');
    if (milestones) {
      const paddings = Array.from(milestones).map(milestone => {
        const height = milestone.offsetHeight;
        return Math.max(height - 75, 0); // Ensure non-negative padding
      });
      setMilestonePaddings(paddings);
    }


  }, [Post]);

  useEffect(() => {
    const timeline = document.querySelector('.timeline__nav');
    if (!timeline) {
      console.error("Element with class 'timeline__nav' not found.");
      return;
    }

    const milestones = document.querySelectorAll('.timeline__section .milestone');
    const offsetTop = parseFloat(window.getComputedStyle(timeline).top);

    const handleResize = () => {
      setStickyTop(timeline.offsetTop - offsetTop);
      handleScroll();
    };

    const handleScroll = () => {
      if (window.scrollY > stickyTop) {
        timeline.classList.add('fixed');
      } else {
        timeline.classList.remove('fixed');
      }

      const viewLine = window.scrollY - 400;
      let active = -1;

      if (scrollTarget === false) {
        milestones.forEach((milestone, index) => {
          if (milestone.offsetTop - viewLine > 0) {
            return false;
          }
          active++;
        });
      } else {
        active = scrollTarget;
      }

      timeline.style.top = `${active * TIMELINE_VALUES.step + TIMELINE_VALUES.start}px`;
      setActiveIndex(active !== -1 ? active : 0);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [Post, stickyTop, scrollTarget]);


  // const handleNavClick = (index) => {
  //   setScrollTarget(index);
  //   setTimeout(() => setScrollTarget(false), 1000);
  // };
  if (!Post) {
    return <div className="load">
      <p className="loader"></p>
    </div>;
  }





  return (
    <div>
      <HelmetHead name="LỊCH SỬ" title="Giới Thiệu Lịch Sử KCL GROUP" description="History" />
      <Banner title="LỊCH SỬ" />
      <article className="timeline container">
        <div>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button className="nav-link active" id="home-tab" data-bs-toggle="tab"
                data-bs-target="#home-tab-pane" type="button" role="tab"
                aria-controls="home-tab-pane" aria-selected="true">CÁC MỐC LỊCH SỬ</button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabIndex={0}>
              <section className="timeline__section">
                <div className="wrapper">
                  {Post && Post.map((item, index) => (
                    <div key={index} id={`year-${item.year}`} className="milestone">
                      <h2>{item.year}</h2>
                      <div className="container">
                        {item.imageUrl && item.title && item.content ? (
                          <Card
                            img={item.imageUrl}
                            title={item.title}
                            subtitle={typeof item.content === 'string' ? item.content : JSON.stringify(item.content)}
                          />
                        ) : (
                          <p>Invalid data for this item</p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </section>
              <nav className="timeline__nav">
                <ul>
                  {Post && Post.map((item, index) => (
                    <a href={`#year-${item.year}`}
                      key={index}
                      // onClick={(e) => {
                      //   e.preventDefault();
                      //   handleNavClick(index);
                      // }}
                    >
                      <li style={{ paddingBottom: `${milestonePaddings[index]}px` }}
                        className={activeIndex === index ? 'active' : ''}
                      >
                        <span>{item.year}</span>
                      </li>
                    </a>
                  ))}
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
}

export default History;
