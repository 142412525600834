import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { ManageImage } from "../../data/mutiImage";
import Box from "@mui/material/Box";
import { IconButton, useTheme, Select, MenuItem, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import DeleteImg from "./Delete";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { message } from "antd";
import { MDBIcon } from 'mdb-react-ui-kit'; 
import ImagePreview from "../../components/imagePreview";
import "./mutiImage.css";


const Image = () => {
  const { getAllImage, CreateImage, DeleteMutiImage } = ManageImage();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [deleteimage, setdeleteImage] = useState([]);

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState([]);

  const handleFileChange = (event) => {
    setSelectedFiles(event.target.files);
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const response = await getAllImage();
    if (response) {
      setImg(response.images);
    }
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalConfirmDeletePost = (img, selectedFiles) => {
    setdeleteImage(img);
    setSelectedFiles(selectedFiles)
    setIsDeleteModalOpen(true);
  };

  const HandelUpload = async () => {
    const formData = new FormData();
    if(selectedFiles.length === 0) {
      message.error("Please select image");
      return;
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > 1024*1024 ){
        message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 1MB");
        return;
      }
      formData.append(' ', selectedFiles[i]);
    }
    setIsLoading(true);
    try {
      const response = await CreateImage(formData);
      if (response) {
        message.success("Upload successfully");
        fetchData();
      }
      setSelectedFiles([]);
      setIsLoading(false);
    } catch (error) {
      message.error("Upload failed");
      setIsLoading(false);
    }
  };
  
  const handleCheckboxChange = (image) => {
    setSelectedImages((prevSelectedImages) => {
      if (prevSelectedImages.includes(image)) {
       
        return prevSelectedImages.filter((img) => img !== image);
      } else {
        return [...prevSelectedImages, image];
      }
    });
  }
  const Deletesuccessfully = async () => {
      fetchData();
  }


  return (
    <div>
      <div>
        <input className="inputfile" type="file"  multiple onChange={handleFileChange} />
      </div>
      <button className="uploadbtn" onClick={HandelUpload} disabled={isLoading}>
         <span className="uploadbtn_top ">
           <MDBIcon fas icon="upload" />
           {isLoading ? 'Uploading...' : 'Upload'}
         </span>
      </button>
      {selectedImages.length > 0 && (
        <button className=" deleteMuti " onClick={() => showModalConfirmDeletePost(selectedImages)}>
          <MDBIcon icon="trash-alt"  className="deleteIcon"/>
           <span> Delete Selected ({selectedImages.length})</span>
        </button>
      )}
      <div>
        <div className="row">
        {img.map((image, index) => (
          <div className="mutile col-3" key={index}>
            <input 
              type="checkbox" 
              checked={selectedImages.includes(image)}
              onChange={() => handleCheckboxChange(image)}
            />
            <ImagePreview image={image} />
            <button className='deletebtn' onClick={() => showModalConfirmDeletePost(image)}>
              <MDBIcon icon="trash-alt" /> 
            </button>
          </div>
        ))}

        <DeleteImg
          open={isDeleteModalOpen}
          onClose={handleModalCloseModalDelete}
          img={deleteimage}
          mutiImage={selectedImages}
          successfully={Deletesuccessfully}
        />
        </div>
      </div>
    </div>
  );
};

export default Image;