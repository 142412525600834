import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDPosts } from "../../data/posts";
import { CRUDPostingCategories } from "../../data/postingCategories";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreatePostModal from "./Create";
import UpdatePostModal from "./Update";
import DeletePost from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ImagePreview from "../../components/imagePreview";
import { render } from "@testing-library/react";
// import { get } from "lodash";
import {useParams} from 'react-router-dom'
import DOMPurify from "dompurify";


const Posts = () => {
      const { id } = useParams();
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [postcategory, setCategory] = useState(null);
  const [Post, setPost] = useState(null);
  const [PostData, setPosts] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isHistory, setHistory] = useState(false);
  const [namecategory, setIdcategory] = useState(0);

  const sanitizeHTML = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
   
    const postData = await GetPosts();

    if (postData && postData.length > 0) {
      const postfiler = postData.filter(post => Number(post.categoryId) === Number(id));
    if (postfiler) {
      setPosts(postfiler);
    }
    if (categoryData) {
      const categoriID = categoryData.filter(ce => Number(ce.categoryId) === Number(id));
      setIdcategory(categoriID[0].categoryName);
      if (categoriID && categoriID[0].categoryName.toLowerCase() === "Lịch Sử".toLowerCase()) 
        {
        setHistory(true);
        }
      else {
        setHistory(false);
          }
      setCategory(categoryData);
    }
  };
}

  const showModalEditPost = (row) => {
    console.log("Selected category for edit:", row);
    setPost(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setPost(null);
  };

  const showModalConfirmDeletePost = (row) => {
    setPost(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreatePost = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  const getCategoryName = (categoryId) => {
    const category = postcategory.find(cat => cat.categoryId === categoryId);
    // console.log("tìm kiếm",categoryId, postcategory);
    return category ? category.categoryName : 'Unknown';
  
  };

  const columns = [
   
    {
      field: "title",
      headerName: "Tiêu đề",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}>
          {params.value}
        </div>),
    },
    {
      field: "introContent",
      headerName: "Giới thiệu",
      flex: 1.5,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}>
          {params.value}
        </div>),
    },  
    {
      field: "content",
      headerName: "Nội dung",
      flex: 2,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}
           dangerouslySetInnerHTML={sanitizeHTML(params.value)}
           >
        </div>),
    },  
    {
      field: "imageUrl",
      headerName: "Hình ảnh",
      flex: 1,
      editable: true,
        renderCell: (params) => (
          <div  style={{ maxWidth: '100px', maxHeight: '100px', padding: '10px',  }} >
           <ImagePreview image={params.value}   />
           </div>
      ),
    },

    {
      field: "authorName",
      headerName: "Tác Giả",
      flex: 0.5,
      editable: true,
    },
    {
      field: "categoryId",
      headerName: "loại",
      flex: 1,
      editable: true,
      renderCell: (params) => getCategoryName(params.value),
    },
    {
      field: "postDate",
      headerName: "Ngày tạo",
      flex: 1,
      editable: true,
    },
    {
      field: "updatedDate",
      headerName: "Ngày cập nhật",
      flex: 1,
      editable: true,
    },
    ...(isHistory ? [{
      field: "year",
      headerName: "Year",
      flex: 0.5,
      editable: true,
    }] : []),
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditPost(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeletePost(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title={namecategory} subtitle="Managing the Posts" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreatePost}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {PostData.length > 0 && (
        <DataGrid
          rows={PostData}
          columns={columns}
          getRowId={(row) => row.postId} // Specify postId as the row ID
          initialState={{
            pagination: { 
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
          rowHeight={110} 
        />
      )}
      <CreatePostModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        postcategory={postcategory}
        onUpdateSuccess={handleUpdateSuccess}
        isHistory={isHistory}
      />
      <UpdatePostModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        Post={Post}
        postcategory={postcategory} 
        onUpdateSuccess={handleUpdateSuccess}
        isHistory={isHistory}
      />
      <DeletePost
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        Post={Post}
        onUpdateSuccess={handleUpdateSuccess}
        isHistory={isHistory}
      />
    </Box>
  );
};

export default Posts;
