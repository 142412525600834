import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDProducts } from "../../data/products";
import { CRUDProductCategorys } from "../../data/productCategorys";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateProductModal from "./Create";
import UpdateProductModal from "./Update";
import DeleteProduct from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import ImagePreview from "../../components/imagePreview";
import {useParams} from 'react-router-dom'
import DOMPurify from "dompurify";

const Products = () => {  // Change 'Products' to 'Products'
    const { id } = useParams();
  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const [Productcategory, setCategory] = useState(null);
  const [Product, setProduct] = useState(null);
  const [ProductData, setProducts] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [namecategory, setcategory] = useState(false);

 const sanitizeHTML = (html) => {
  return {
    __html: DOMPurify.sanitize(html)
  };
};
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    const categoryData = await GetProductCategorys();
    const ProductData = await GetProducts();
    if (ProductData) {


      if (ProductData && ProductData.length > 0) {
        // Sử dụng filter thay vì find để lấy tất cả sản phẩm phù hợp
        const ProductCategori = ProductData.filter(product => Number(product.categoryId) === Number(id));
        const CategoriId = categoryData.filter(ca => Number(ca.id) === Number(id));
        if (CategoriId) {
         setcategory(CategoriId[0].name);
       
      }
      setProducts(ProductCategori);
    }
    if (categoryData) {
      setCategory(categoryData);
    }
  };
  }
  const showModalEditProduct = (row) => {
    console.log("Selected category for edit:", row);
    setProduct(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setProduct(null);
  };

  const showModalConfirmDeleteProduct = (row) => {
    setProduct(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateProduct = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };
  const getCategoryName = (categoryId) => {
    const category = Productcategory.find(cat => cat.id === categoryId);
    // console.log("tìm kiếm",categoryId, Productcategory);
    return category ? category.name : 'Unknown';
  
  };

  const columns = [
    {
      field: "name",
      headerName: "name",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}>
          {params.value}
        </div>),
    },
    {
      field: "introContent",
      headerName: "Giới thiệu",
      flex: 3,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}
        dangerouslySetInnerHTML={{ __html: params.value }}
          >
        </div>),
    },

    {
      field: "description",
      headerName: "Nội dung",
      flex: 3,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}
        dangerouslySetInnerHTML={{ __html: params.value }}
        >
          
        </div>),
    },
    
    // {
    //   field: "addedTime",
    //   headerName: "Product Date",
    //   flex: 1,
    //   editable: true,
    // },
    // {
    //   field: "updatedTime ",
    //   headerName: "update Date",
    //   flex: 1,
    //   editable: true,
    // },
    {
      field: "retailprice",
      headerName: "Giá bán",
      flex: 0.5,
      editable: true,
    },
    {
      field: "marketprice",
      headerName: "Giá Thị Trường",
      flex: 0.5,
      editable: true,
    },
    {
      field: "categoryId",
      headerName: "loại",
      flex: 1,
      editable: true,
      renderCell: (params) => getCategoryName(params.value),
    },
    
    {
      field: "urlImage",
      headerName: "Image Data",
      flex: 1,
      editable: true,
        renderCell: (params) => (
          <div  style={{ maxWidth: '100px', maxHeight: '100px', padding: '10px',  }} >
           <ImagePreview image={params.value}   />
           </div>
      ),
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditProduct(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteProduct(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title={namecategory} subtitle="Managing the Products" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateProduct}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {ProductData.length > 0 && (
        <DataGrid
          rows={ProductData}
          columns={columns}
          getRowId={(row) => row.id} // Specify ProductId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
          rowHeight={110} 
        />
      )}
      <CreateProductModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        Productcategory={Productcategory}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdateProductModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        Product={Product}
        Productcategory={Productcategory}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeleteProduct
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        Product={Product}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default Products;
