import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { CRUDProductCategorys } from "../../data/productCategorys";
const DeleteProductCategorieModal = ({ open, onClose, category, onUpdateSuccess }) => {
    const {DeleteProductCategoryById} = CRUDProductCategorys();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await DeleteProductCategoryById(category.id);
      message.success('ProductCategory deleted successfully');
      setConfirmLoading(false);
      onClose();
      onUpdateSuccess();
    } catch (error) {
    console.log("lỗi",error);
      message.error('Failed to delete ProductCategory');
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete ProductCategory ${category?.name}`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to delete ProductCategory "{category?.name}"?</p>
    </Modal>
  );
};

export default DeleteProductCategorieModal;
