
import React, {  useEffect, useState } from "react";
import Slider from "react-slick";
import Partner from "../components/Partner/Partner";
import "./Partner.css"
 import {ManageImage} from "../data/mutiImage"
 import axios from "../data/custom/axios";
import {
 Banner,
 HelmetHead
} from "../components"


function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",}}
        onClick={onClick}
      />
    );
  }

function PartnerHome() {

  const { getAllImage } = ManageImage();
  const [img, setImg] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await getAllImage();
    if (response) {
      setImg(response.images);
    }
  };
  
    // const imagePaths = img.map(image => image.default);
    // console.log(imagePaths);

    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 2,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
        
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              rows: 2,
            }
          }
        ]
      };
const comments = [{
//   "noidung" : `Phần mềm quản lý phòng khám K.Clinic là phần mềm tối ưu nhất tôi sử dụng cho phòng khám của mình từ trước đây nay. Đội ngũ phát triển phần mềm am hiểu sự phức tạp và những bất cập trong quy trình khám bệnh. Giúp phòng khám của tôi tiết kiệm được thời gian, nhân lực, đẩy mạnh khâu tổ chức quản lý cũng như chăm sóc khách hàng tối ưu hơn rất nhiều`,
// "userComment" :"B.s Bình",
// "address" : "Phòng khám KCL"

}]

const hasValidComments = comments.some(item => item.noidung && item.userComment && item.address);

const showImage = (url) => {
  return (`${axios.defaults.baseURL}/img/${url}`)
}

return( 
<>
<HelmetHead name="ĐỐI TÁC-KHÁCH HÀNG" title="Về ĐỐI TÁC-KHÁCH HÀNG Của KCL" description="ĐỐI TÁC-KHÁCH HÀNG "  />
<Banner title="ĐỐI TÁC-KHÁCH HÀNG" />
<div className="container">

<div className="partener ">
    <div className="row ">
    {hasValidComments ? (
      comments.map((item, index) => (
        item.noidung && item.userComment && item.address ? (
          <Partner
            key={index}
            comment={item.noidung}
            userComment={item.userComment}
            address={item.address}
          />
        ) : null
      ))
    ):("")}
       
    </div>
</div >

<div className="slider-container partener-slick ">
        <Slider {...settings}>
          {img &&  img.map((imagePath, index) => (
            <div className="img-card" key={index}>
              <img src={showImage(imagePath)} alt={`Image ${index}`} />
            </div>
          ))}
        </Slider>
      </div>
</div>

</>
)}
export default PartnerHome;