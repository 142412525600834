
import "./conten.css";
import React, { useState, useEffect } from 'react';
import { CRUDProducts } from '../../../data/products';
import { useParams } from 'react-router-dom';
import ImagePreview from "../../child/imagePreview";
import TopNews from "../../item/TopNews";
import IntroPdoduct from "./IntroPdoduct";
import DOMPurify from "dompurify";


const ProductDetail = () => {
  const { id } = useParams();
  const { GetProductById } = CRUDProducts();
  const [product, setProduct] = useState(null);
  const [isShop, setShop] = useState(false);
  const sanitizeHTML = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    try {
      const productData = await GetProductById(id);
      if (productData) {
        setProduct(productData);
        console.log(productData.categoryId        )
        if (productData.categoryId === 10){
          setShop(true);
        }
      }
    } catch (error) {
      console.error('Error fetching product data:', error);
    }
  };

  if (!product) {
    return <p>Loading...</p>;
  }


  const news = [
    "Tin tức mới nhất về công nghệ",
    "Cập nhật tình hình thời sự",
    "Sự kiện nổi bật trong tuần"
  ];

  return (
    <div className=" container productdetail">
       {isShop ? (
        <div className="MainContent">
      <IntroPdoduct 
      name = {product.name}
      img = {product.urlImage  }
      // price = {product.price}
      title={product.introContent}
      retailprice = {product.retailprice}
      marketprice = {product.marketprice}
       />
      </div> 
      ):(
      
      <div className="container">
        <h1 className="content-title conten_H1">{product.name}</h1>
        <p className="conten_date">• {new Date(product.addedTime).toLocaleDateString()}</p>
      </div>
      ) }

      <div className="MainContent">
        <div className="content">
          <div className="main-content">
          <div
            dangerouslySetInnerHTML={{ __html: product.description }}
          />
          </div>
          <div className="sidebar">
                    <h2>Tin tức mới</h2>
                    <ul>
                      {news.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;