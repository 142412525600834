import React, { useState } from "react";
import { Modal, message } from "antd";
import { ManageImage } from "../../data/mutiImage";
import ImagePreview from "../../components/imagePreview";

const DeleteImg = ({ open, onClose, img, mutiImage, successfully }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { DeleteImage, DeleteMutiImage } = ManageImage();

  const handleOk = async () => {
    if (mutiImage.length > 0) {
      await deletemuti();
    } else {
      await deleteOne();
    }
  };

  const deletemuti = async () => {
    setConfirmLoading(true);
    try {
      await DeleteMutiImage(mutiImage, "Deleted");
      message.success("Deleted successfully");
      setConfirmLoading(false);
      successfully();
      onClose();
    } catch (error) {
      console.log("Error:", error);
      message.error("Failed to delete");
      setConfirmLoading(false);
    }
  };

  const deleteOne = async () => {
    setConfirmLoading(true);
    try {
      await DeleteImage(img, "Canceled");
      message.success("Deleted successfully");
      setConfirmLoading(false);
      successfully();
      onClose();
    } catch (error) {
      console.log("Error:", error);
      message.error("Failed to delete");
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {

    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete Image: ${img}`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to delete Image: {img}?</p>
      <ImagePreview image={img} />
    </Modal>
  );
};

export default DeleteImg;
