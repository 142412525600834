import React, {useEffect, useState} from "react";
import { Helmet } from "react-helmet";
import logo from "../KCL-logo.svg";
import axios from "../data/custom/axios";
import {CRUDBanners} from "../data/banner"
const HelmetHead=(Head)=> {
  const {GetBanners} = CRUDBanners();
  const [ImgBanner, setBanners] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const BannerData = await GetBanners();
   
    if (BannerData) {
      const GetImgBanner = BannerData.find(banner => banner.bannerName.toLowerCase() === Head.name.toLowerCase());
      if (GetImgBanner) {
        setBanners(GetImgBanner);
      }
    }
  };
  return (
    <Helmet>
      <meta charSet="UTF-8" />
      <title>{Head.name}</title>
      <link rel="icon" type="image/svg+xml" href={logo} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="google-site-verification" content="yqKl8O7gfEnF3eNM8SkVWhzFbqp8mplQkhqBeqi0Ilk" />
      <meta name="keywords" content="Lịch Sử,SOLUTION, HEALTHTECH, TECHNICAL" />
      <link rel="canonical" href="https://kclgroup.vn" />
      <meta name="description" content="KCL Group là nhà phát triển phần mềm và chuyển đổi số" />
    
      <meta name="description" content="Learn more about us on this page" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={Head.tittle} />
        <meta name="twitter:description" content={Head.description} />
        <meta name="twitter:image" content={ImgBanner} />

    </Helmet>
  );
}

export default HelmetHead;
