import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDProductCategorys } from "../../data/productCategorys";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateProductCategorieModal from "./Create";
import UpdateProductCategorieModal from "./Update";
import DeleteProductCategorieModal from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";

const ProductCategories = () => {
  const { GetProductCategorys } = CRUDProductCategorys();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [category, setProductCategorie] = useState(null);
  const [categories, setProductCategories] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const categoryData = await GetProductCategorys();
    if (categoryData) {
      setProductCategories(categoryData);
    }
  };

  const showModalEditProductCategorie = (row) => {
    console.log("Selected category for edit:", row);
    setProductCategorie(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setProductCategorie(null);
  };

  const showModalConfirmDeleteProductCategorie = (row) => {
    setProductCategorie(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateProductCategorie = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  const columns = [
    { field: "id", headerName: "ID", flex: 0.3 },
    {
      field: "name",
      headerName: "Tên danh mục",
      flex: 0.7,
      editable: true,
    },
    {
      field: "createdTime",
      headerName: "Ngày tạo",
      flex: 1,
      editable: true,
    },
    {
      field: "updatedTime",
      headerName: "Ngày cập nhật",
      flex: 1,
      editable: true,
    },
    {
      field: "status",
      headerName: "status",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <span>{params.value === 0 ? 'Active' : 'Inactive'}</span>
      ),
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditProductCategorie(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteProductCategorie(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="ProductCategories" subtitle="Managing the ProductCategories" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateProductCategorie}
      >
      <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.id} // Specify categorieId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
        />
      )}
      <CreateProductCategorieModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdateProductCategorieModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeleteProductCategorieModal
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        category={category}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default ProductCategories;
