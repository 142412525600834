import React, { useEffect, useState } from 'react';
import Contencard from '../components/item/contenCard.jsx'; // Đảm bảo đường dẫn đúng
import Banner from '../components/child/banner'; // Đảm bảo đường dẫn đúng
import HelmetHead from '../components/HelmetHead';
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import "./Strategic.css"
function Strategic() 
{
    const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
const [GetIntro, SetIntro] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      if (categoryData && postData) {
        const newsCategory = categoryData.find(ce => ce.categoryName.toLowerCase() === "TẦM NHÌN CHIẾN LƯỢC".toLowerCase());
        console.log("News Category:", newsCategory);
        if (newsCategory) {
          const filteredPosts = postData.filter(po => po.categoryId === newsCategory.categoryId);
          const findIntro = filteredPosts.find(po => po.title && po.title.toLowerCase().includes("INTRO".toLowerCase()));
          if (findIntro) {
            setPosts(filteredPosts.filter(po => po.title!== findIntro.title));
            console.log("fildData:", findIntro);
            console.log("Post Data:", posts);
            SetIntro(findIntro);
          }
          else{
            setPosts(filteredPosts);
          }
        }
    
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
    return(
        <>
          <HelmetHead name="TẦM NHÌN" title="TẦM NHÌN CHIẾN LƯỢC" description="TẦM NHÌN CHIẾN LƯỢC" />
      <Banner title="TẦM NHÌN CHIẾN LƯỢC" />
      <div className='Container Strategic'>
          <div className="container">
                <div className="about-us">
                  <div className="row">
                    <div className="block-our-business-title py-4 intro">
                      <h2 className="font-weight-light">{GetIntro? (GetIntro.introContent):("lỗi") }</h2>
                      <p className="">
                        {GetIntro?( GetIntro.content):("lỗi")}
                        </p>
                    </div>
              </div>
            </div>
          </div>
          <div className=" k-hel py-5 ">
            {posts ? (
              posts.map((item, index) => (
                <Contencard
                  key={index}
                  index={index}
                  name={item.title}
                  title={item.content}
                  content={item.content}
                  link={`tin_tuc/${item.postId}`}
                  img={item.imageUrl}
                />
              ))
            ) : (
              <div className="load">
                <p className="loader"></p>
              </div>
            )}
          </div>
      </div>
    </>
        
    );
}

export default Strategic;
