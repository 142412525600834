import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDPdfs } from "../../data/Pdf";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreatePdfModal from "./Create";
import UpdatePdfModal from "./Update";
import DeletePdf from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
//import pdfPreview from "../../components/pdfPreview";
import { render } from "@testing-library/react";
// import { get } from "lodash";
import {useParams} from 'react-router-dom'
import DOMPurify from "dompurify";

import axios from "../../custom/axios";
import PdfPreview from "./PdfPreview";

const Pdfs = () => {
      const { id } = useParams();
  const { GetPdfs } = CRUDPdfs();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Pdfcategory, setCategory] = useState(null);
  const [Pdf, setPdf] = useState(null);
  const [PdfData, setPdfs] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const sanitizeHTML = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    const data = await GetPdfs();
    console.log("Getpdf",data)
    if (data){
      setPdfs(data);
      console.log("Setpdf",PdfData)
    }
  };

  const showModalEditPdf = (row) => {
    console.log("Selected category for edit:", row);
    setPdf(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setPdf(null);
  };

  const showModalConfirmDeletePdf = (row) => {
    setPdf(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreatePdf = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  // const PdfPreview = ({ url }) => {
  //   const pdfUrl = `${axios.defaults.baseURL}/pdf/${url}`;
  
  //   return (
  //     <iframe
  //       src={pdfUrl}
  //       style={{ width: '100%', height: '100px' }} // Adjust width and height as necessary
  //       title="PDF Preview"
  //     />
  //   );
  // };

  const columns = [ 
    {

      field: "name",
      headerName: "Tên",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}>
          {params.value}
        </div>),
    },
    {
      field: "introContent",
      headerName: "Giới thiệu",
      flex: 1.5,
      editable: true,
      renderCell: (params) => (
        <div style={{
          width: '100%',
          height: '100%',
          padding: '10px',
          overflow: 'auto',
          whiteSpace: 'normal',
          lineHeight: 'normal'
        }}>
          {params.value}
        </div>),
    },  
    {
      field: "urlPdf",
      headerName: "Pdf",
      flex: 1,
      editable: true,
      renderCell: (params) => (
        <div style={{ maxWidth: '100px', maxHeight: '100px', padding: '10px' }}>
            <PdfPreview url={`${axios.defaults.baseURL}/pdf/${params.value}`} />
        </div>
      ),
    },

    {
      field: "authorName",
      headerName: "Tác Giả",
      flex: 0.5,
      editable: true,
    },
    {
      field: "addedTime",
      headerName: "Ngày tạo",
      flex: 1,
      editable: true,
    },
    {
      field: "updatedTime",
      headerName: "Ngày cập nhật",
      flex: 1,
      editable: true,
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditPdf(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeletePdf(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box m="20px">
      <Header title="Pdf" subtitle="Managing the Pdfs" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreatePdf}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {PdfData.length > 0 && (
        <DataGrid
          rows={PdfData}
          columns={columns}
          getRowId={(row) => row.id} // Specify PdfId as the row ID
          initialState={{
            pagination: { 
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30, 40, 50]}
          rowHeight={110} 
        />
      )}
      <CreatePdfModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        Pdfcategory={Pdfcategory}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdatePdfModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        Pdf={Pdf}
        Pdfcategory={Pdfcategory} 
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeletePdf
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        Pdf={Pdf}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default Pdfs;
