import "./Par.css";
import React from "react";

 const Partner = (partner) => {
    return (
        <>
             
                        <div className="strategic-item-wrap col-md-4" >
                            <div className="strategic-item-content">
                                <p className="fpt-scroll">{partner.comment}
                                </p>
                                <div >
                                    <img className="lazyload"
                                src="/-/media/project/fpt-corporation/fpt/about-us/partners-and-customers/slider/stephen-foley/rheem-mobile.gif" />
                                </div>
                                <hr />
                                <div className="person-title">{partner.userComment}
                                </div>
                                <div className="person-content" >{partner.address}<br />
                                    </div>
                            </div>
                        </div>
                    
        </>
    );
}
export default Partner;
