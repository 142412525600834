import React, { useEffect, useState } from 'react';
import Banner from "../child/banner.jsx"
import { CRUDProducts } from '../../data/products.js';
import { CRUDProductCategorys } from '../../data/productCategorys.js';
import Contencard from '../item/contenCard.jsx';
import {
  HelmetHead
} from "../index.js";


function K_SOLUTION() {


  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const [Product, setProduct] = useState([]);
  const [GetIntro, setIntro] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetProductCategorys();
      const productData = await GetProducts();
      if (categoryData) {
        const categoriFind = categoryData.find(ce => ce.name.toLowerCase() === "K SOLUTION".toLowerCase());
      if (categoriFind) {
       const filterProduct = productData.filter(po => po.categoryId === categoriFind.id);
       const findIntro = productData.find(po => po.name && po.name.toLowerCase().includes("HOẠT ĐỘNG".toLowerCase())
      );
       console.log("intro:", findIntro);
       
         if (findIntro) {
          setProduct(filterProduct.filter(item => item.id !== findIntro.id));
         setIntro(findIntro);
         }
         else{
          setProduct(filterProduct)
         }

      }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!Product) {
    return <div className="load">
    <p className="loader"></p>
  </div>;
  }

  return (
    <>
     <HelmetHead name="K SOLUTION" title="Giới Thiệu K SOLUTION" description="K_SOLUTION"  />
   <Banner title="K SOLUTION" />
      <div className="container">
          <div className="about-us">
            <div className="row">
              <div className="block-our-business-title py-4">
              <h2 className="font-weight-light">{GetIntro.name}</h2>
                  <p className="block-our-business-desc-title">
                    {GetIntro.introContent}
                    </p>
              </div>
            </div>
      </div>
      </div>
      <div className=" k-hel py-5">
      {Product.length > 0 ? (
          Product.map((item, index) => (
            <Contencard
              key={index}
              index={index}
              name={item.name}
              title={item.introContent}
              content={item.description}
              link={`bai_viet/${item.id}`}
              img={item.urlImage}
            />
          ))
        ) : (
          <div className="load">
            <p className="loader"></p>
          </div>
        )}
        </div>

      
      </>
  );
}

export default K_SOLUTION;
