import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDPdfs } from "../../data/Pdf";
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import { IconButton, useTheme } from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import axios from "axios";
import "../../custom/mutationObserver"
import PdfPreview from "./PdfPreview";
const { Option } = Select;
const CreatePdfModal = (props) => {
  const { id } = useParams();
  const [pdfPreview, setpdfPreview] = useState(false);
  const { open, onClose, onUpdateSuccess, Pdfcategory, isHistory } = props;
  const { CreatePdf } = CRUDPdfs();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const now = new Date();
  const quillRef = useRef(null);

  const [isEditModalOpen, setIsModalOpen] = useState(false);
  //const uploadedpdfsRef = useRef([]);
  //const [quillKey, setQuillKey] = useState(0); 
  let Mutilimg ;



  useEffect(() => {
    form.setFieldsValue({
      PdfDate: now.toISOString().slice(0, 16), // Format the datetime-local value
      updatedDate: now.toISOString().slice(0, 16),
      authorName: '<NAME>',
      categoryId: Number(id),
      status: 0,
    });
  }, [now, form]);



  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('name', values.name);
      formData.append('IntroContent', values.IntroContent);
      const pdfFile = form.getFieldValue('pdf');
      if (pdfFile) {
        if (pdfFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng quá lớn, vui lòng chọn file có dung lượng dưới 10MB");
          return;
        }
        formData.append('PdfFile', pdfFile);
      }
      formData.append('UrlPdf', values.pdfUrl); // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('AddedTime', values.PdfDate);
      formData.append('UpdatedTime', values.updatedDate);
      formData.append('Status', values.status);

      // for (let [key, value] of formData.entries()) {
      //   console.log("không biết viết gì", key, value);
      // }
       console.log("nội dung trong formdata", [...formData]);
      await CreatePdf(formData);
      message.success('Pdf created successfully');
      setpdfPreview(null);
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error creating Pdf:', error);
      message.error('Failed to create Pdf. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };


  return (
    <Modal
      width="55%"
      title="Create new Pdf"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="name"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the Pdf title!' }]}
        >
          <Input />
         
        </Form.Item>
        <Form.Item
          name="IntroContent"
          label="Giới thiệu"

        >
          <Input.TextArea rows={9} />
        </Form.Item>
        <input
          type="file"
          accept="pdf/*"
          onChange={(e) => {
            const file = e.target.files[0];
            form.setFieldsValue({ pdf: file });

            // Tạo URL để preview ảnh
            if (file) {
              const previewURL = URL.createObjectURL(file);
              setpdfPreview(previewURL);
            } else {
              setpdfPreview(null);
            }
          }}
        />
        {pdfPreview && (
          <div style={{ marginTop: '10px' }}>
            <PdfPreview url={pdfPreview}/>
          </div>
        )}
        <Form.Item
          name="pdfUrl"
          label="Đường dẫn ảnh"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="PdfDate"
          label="Ngày Đăng"
          rules={[{ required: true, message: 'Please input the Pdf date!' }]}
        >
          <Input type="datetime-local" />
        </Form.Item>
        <Form.Item
          name="authorName"
          label="Tác Giả"
          rules={[{ required: true, message: 'Please input the author name!' }]}
        >
          <Input />
        </Form.Item>
        
        <Form.Item
          name="updatedDate"
          label="Ngày Cập Nhật"
          rules={[{ required: true, message: 'Please input the updated date!' }]}
        >
          <Input type="datetime-local" disabled />
        </Form.Item>

        <Form.Item
          name="status"
          label="Trạng Thái"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
          <Select>
            <Option value={0}>active</Option>
            <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>

    </Modal>
  );
};
export default CreatePdfModal;
