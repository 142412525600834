// Album.js
import React, {useEffect, useState} from 'react';
import AlbumItem from '../components/item/AlbumItem';
import { ManageImage } from "../data/mutiImage";
import axios from '../data/custom/axios';
 import "./album.css"
//import "./album2.css"
const Album = () => {
    const { getAllImage, CreateImage, DeleteMutiImage } = ManageImage();
    const [img, setImg] = useState([]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line
      }, []);
    
      const fetchData = async () => {
        const response = await getAllImage();
        if (response) {
            console.log("image", response.images)
          setImg(response.images);
          console.log("saveimage", img)
        }
      };
    
      const ShowImage =(image) =>{
        return (`${axios.defaults.baseURL}/img/${image}`)
      }

  const [flippedPages, setFlippedPages] = useState(Array(img.length).fill(false));

  const handlePageClick = (index) => {
    console.log(index)
    const newFlippedPages = [...flippedPages];
    newFlippedPages[index] = !newFlippedPages[index];
    // Check if it's the last page and reset if necessary
    if (index ===  1 && newFlippedPages[index]) {
      setTimeout(() => {
        setFlippedPages(Array(img.length).fill(false));
      }, 600); // Adjust the timeout to match your flip animation duration
    } else {
      setFlippedPages(newFlippedPages);
    }
  };
  return (
    <div className="book">
      <div id="pages" className="pages">
        <div className="page">
          <p>Open Me, <br />please!</p>
        </div>
        {img && img.map((album, index) => (
          <div
            key={index}
            className={`page ${flippedPages[index] ? 'flipped' : ''}`}
            onClick={() => handlePageClick(index)}
          >
            <img src={ShowImage(album)} alt={`album-${index}`} />
          </div>
        ))}
        <div className="page">
          <p>end</p>
        </div>
      </div>
    </div>
  );
};

export default Album;
