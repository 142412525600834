import axios from "./custom/axios";
const CRUDBanners = () => {
  const GetBanners = async () => {
    try {
      const response = await axios.get(`/banner`);
      console.log(response);
      return response;
    } catch (error) {
      console.error("Error during fetching categories:", error);
      return false;
    }
  };
  const CreateBanner =async (formData) => {
    try {
      const res = await axios.post(`/banner`,formData)
      console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      throw error; 
    }
  };

  const UpdateBanner =async (formData) => {
    try {
      const res = await axios.put(`/banner/${formData.get("Id")}`, formData);
      console.log(res.data);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      throw error; 
    }
  };
  const DeleteBannerById = async (id) =>{
    try {
      const res = await axios.delete(`/admin/category/delete/${id}`);
      console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete category",error);
      throw error;
    };
  }
  return { GetBanners, UpdateBanner, DeleteBannerById, CreateBanner };
}


export {CRUDBanners};
