import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { CRUDPostingCategories } from "../../data/postingCategories";
const DeletePostingCategorie = ({ open, onClose, category, onUpdateSuccess }) => {
    const {DeletePostingCategorieById} = CRUDPostingCategories();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    console.log("category",category.categoryId);
    setConfirmLoading(true);
    try {
      await DeletePostingCategorieById(category.categoryId);
      message.success('PostingCategorie deleted successfully');
      setConfirmLoading(false);
      onClose();
      onUpdateSuccess();
    } catch (error) {
    console.log("lỗi",error);
      message.error('Failed to delete PostingCategorie');
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete PostingCategorie ${category?.categoryName}`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to delete PostingCategorie "{category?.categoryName}"?</p>
    </Modal>
  );
};

export default DeletePostingCategorie;
