import axios from "./custom/axios";
const ManageImage = () => {
    const getAllImage = async () => {
        try {
            const response = await axios.get("/MutilImg");
            console.log("data", response);
            return response;
          } catch (error) {
            console.error("Error during fetching orders:", error);
            return false;
          }
    }
    return {getAllImage};
}

export {ManageImage};

