import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { CRUDPdfs } from "../../data/Pdf";
const DeletePdf = ({ open, onClose, Pdf, onUpdateSuccess }) => {



    const {DeletePdfById} = CRUDPdfs();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const id = Pdf?.PdfId;  
  useEffect(() => {
    // if (category) {
    //   console.log("categorydelete", id);
    // }
  })

    

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await DeletePdfById(id);
     
      message.success('Pdf deleted successfully');
      setConfirmLoading(false);
      onClose();
      onUpdateSuccess();
    } catch (error) {
    console.log("lỗi",error);
      message.error('Failed to delete Pdf');
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete Pdf ${Pdf?.title}`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Bạn muốn xóa bài viết này chứ? "{Pdf?.title}"?</p>
    </Modal>
  );
};

export default DeletePdf;
