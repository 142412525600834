import React from "react";

import CustomCard from "./mutiAbout/Customcard";
import Banner from "./child/banner";
import Intro from "./child/intro";
import IntroAbout from "./item/IntroAbout";

function About() {

return (
    <>
      <Banner title="GIỚI THIỆU" />
      <IntroAbout />
      {/* <CustomCard />
      <Intro /> */}
    </>
  );
}
export default About;

