import "./style/conten.css"
import React, { useState, useEffect } from 'react';
import { CRUDPosts } from '../../data/posts';
import { useParams } from 'react-router-dom';
import ImagePreview from "../child/imagePreview";
import DOMPurify from "dompurify";


const MainContent = () => {
  const { id } = useParams();
  const { GetPostsById } = CRUDPosts();
  const [post, setPost] = useState(null);
  const sanitizeHTML = (html) => {
    return {
      __html: DOMPurify.sanitize(html)
    };
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  const fetchData = async () => {
    try {
      const postData = await GetPostsById(id);
      if (postData) {
        setPost(postData);
      }
    } catch (error) {
      console.error('Error fetching post data:', error);
    }
  };
  if (!post) {
    return <div className="load">
      <p className="loader"></p>
    </div>;
  }

    const news = [
      "Tin tức mới nhất về công nghệ",
      "Cập nhật tình hình thời sự",
      "Sự kiện nổi bật trong tuần"
    ];
  
    return (
      <div className="main">
          <div className="container">
          <h1 className="conten_H1" >{post && post.title}</h1>
          <p className="conten_date">•  {new Date(post && post.postDate).toLocaleDateString()}</p>

          </div>
              <div className="MainContent">
                <div className="content ">
                  <div className="main-content ">
                <div  dangerouslySetInnerHTML={{__html: post &&  post.content}}/>
                  </div>
                  <div className="sidebar">
                    <h2>Tin tức mới</h2>
                    <ul>
                      {news.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
      </div>
    );
};
export default MainContent;