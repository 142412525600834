import React, { useState, useEffect, useRef } from "react";
import { Modal, Input, Form, message, Select, Button } from 'antd';
import { CRUDPosts } from "../../data/posts";
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'
import UploadImg from "../UploadImage/UploadImg";
import { IconButton, useTheme } from "@mui/material";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import axios from "axios";
import "../../custom/mutationObserver"

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
const { Option } = Select;
const CreatePostModal = (props) => {
  const { id } = useParams();
  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, onUpdateSuccess, postcategory, isHistory } = props;
  const { CreatePost } = CRUDPosts();

  const [confirmLoading, setConfirmLoading] = useState(false);
  const now = new Date();
  const editorRef = useRef(null);

  const [isEditDrawerOpen, setIsDrawerOpen] = useState(false);
  //const uploadedImagesRef = useRef([]);
  //const [quillKey, setQuillKey] = useState(0); 
  let Mutilimg;

  
  const [content, setContent] = useState('');
  const [form] = Form.useForm();



  useEffect(() => {
    form.setFieldsValue({
      postDate: now.toISOString().slice(0, 16), // Format the datetime-local value
      updatedDate: now.toISOString().slice(0, 16),
      authorName: '<NAME>',
      categoryId: Number(id),
      status: 0,
    });
  }, [now, form]);



  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('title', values.title);
      formData.append('IntroContent', values.IntroContent);
      formData.append('content', content);
      const imageFile = form.getFieldValue('image');
      if (imageFile) {
        if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('Image', imageFile);
      }
      formData.append('imageUrl', values.imageUrl); // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('postDate', values.postDate);
      formData.append('authorName', values.authorName);
      formData.append('categoryId', values.categoryId);
      formData.append('updatedDate', values.updatedDate);
      if (values.Year !== undefined && values.Year !== null) {
        formData.append('year', values.Year);
      }
      
      formData.append('status', values.status);

      // for (let [key, value] of formData.entries()) {
      //   console.log("không biết viết gì", key, value);
      // }
      console.log("nội dung trong formdata", [...formData]);
      await CreatePost(formData);
      message.success('Post created successfully');
      setImagePreview(null);
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error creating post:', error);
      message.error('Failed to create post. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };

  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = 2020; year <= currentYear; year++) {
    years.push(year);
  }


  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('files', file);
  
    try {
      const response = await axios.post(`http://localhost:5256/api/MutilImg/PostImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Server response:', response.data);
  
      if (response.data && response.data.fileNames && response.data.fileNames.length > 0) {
        const imageUrl = `http://localhost:5256/api/img/${response.data.fileNames[0]}`;
        console.log('Image URL:', imageUrl);
  
        // Sử dụng uploadHandler để chèn URL của ảnh vào nội dung của SunEditor
        uploadHandler({
          result: [{ url: imageUrl }] // Chèn URL của ảnh vào editor
        });
      } else {
        throw new Error('No file names returned from the server.');
      }
    } catch (error) {
      console.error('Image upload failed:', error);
      message.error('Failed to upload image. Please try again later.');
      uploadHandler({
        result: [{ error: 'Failed to upload image' }] // Trả về lỗi nếu upload thất bại
      });
    }
  
    // Trả về false để chặn việc chèn ảnh base64 vào SunEditor
    return false;
  };
  

  const handleEditorChange = (content) => {
    setContent(content); // Capture the editor content
  };
  //end upload img qill


  const showImglist = () => {
    setIsDrawerOpen(true);
  };

  const closeImglist = () => {
    setIsDrawerOpen(false);
  };
  return (
    <Modal
      width="55%"
      title="Create new post"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the post title!' }]}
        >
          <Input />

        </Form.Item>
        <Form.Item
          name="IntroContent"
          label="Giới thiệu"
        >
          <Input.TextArea rows={9} />
        </Form.Item>
        <Form.Item
          name="content"
          label="Nội dung"
        >
          <IconButton
            onClick={showImglist}
          >
            <PermMediaIcon />
          </IconButton>
          <SunEditor
            setContents={content}
            onImageUploadBefore={handleImageUploadBefore} // Chặn hành vi mặc định trước khi upload
            onChange={handleEditorChange}
            setOptions={{
              buttonList: [
                ['undo', 'redo'],
                ['bold', 'underline', 'italic', 'strike'],
                ['list', 'indent', 'outdent'],
                ['align', 'fontSize', 'formatBlock'],
                ['image', 'link'],
                ['removeFormat']
              ],
            }}
          />
        </Form.Item>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => {
            const file = e.target.files[0];
            form.setFieldsValue({ image: file });

            // Tạo URL để preview ảnh
            if (file) {
              const previewURL = URL.createObjectURL(file);
              setImagePreview(previewURL);
            } else {
              setImagePreview(null);
            }
          }}
        />
        {imagePreview && (
          <div style={{ marginTop: '10px' }}>
            <img
              src={imagePreview}
              alt="Preview"
              style={{ maxWidth: '200px', maxHeight: '200px' }}
            />
          </div>
        )}
        <Form.Item
          name="imageUrl"
          label="Đường dẫn ảnh"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="postDate"
          label="Ngày Đăng"
          rules={[{ required: true, message: 'Please input the post date!' }]}
        >
          <Input type="datetime-local" />
        </Form.Item>
        <Form.Item
          name="authorName"
          label="Tác Giả"
          rules={[{ required: true, message: 'Please input the author name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="categoryId"
          label="Loại Bài Viết"
          rules={[{ required: true, message: 'Please select the category ID!' }]}
        >
          <Select>
            {postcategory && postcategory.length > 0 ? (
              postcategory.map(category => (
                <Option key={category.categoryId} value={category.categoryId}>
                  {category.categoryName}
                </Option>
              ))
            ) : (
              <Option value="" disabled>
                No categories available
              </Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="updatedDate"
          label="Ngày Cập Nhật"
          rules={[{ required: true, message: 'Please input the updated date!' }]}
        >
          <Input type="datetime-local" disabled />
        </Form.Item>
        {isHistory && (
          <Form.Item
            name="Year"
            label="Year"
          >
            <Select placeholder="Select a year">
              {years.map(year => (
                <Option key={year} value={year}>
                  {year}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          name="status"
          label="Trạng Thái"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
          <Select>
            <Option value={0}>active</Option>
            <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
      <UploadImg
        open={isEditDrawerOpen}
        onClose={closeImglist}
      />
    </Modal>
  );
};
export default CreatePostModal;
