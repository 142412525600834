import axios from "../custom/axios";
import {message} from "antd";
const ManageImage = () => {



  const GetImagedist = async () => {
    try {
        const response = await axios.get("/MutilImg/dist");
        // console.log("data", response);
        return response;
      } catch (error) {
        console.error("Error during fetching orders:", error);

        return false;
      }
      }

    const getAllImage = async () => {
        try {
            const response = await axios.get("/MutilImg");
            console.log("data", response);
            return response;
          } catch (error) {
            console.error("Error during fetching orders:", error);

            return false;
          }
    }
    const CreateImage = async (data) => {
        try {
            const response = await axios.post(`/MutilImg`,data);
            console.log("detail item", response);
            return response;
          } catch (error) {
            console.error("Error during fetching orders:", error);
            if (error.response && error.response.name === 403 ) {
              message.error('Bạn không có quyền thêm danh mục này.');
            } else if (error.response && error.response.status === 400   || error.response.status === 500) {
              message.error('Vui lòng xóa sản phẩm trước.');
             } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
              message.error('Vui Lòng Đăng Nhập lại.');
             }
            return false;
          }
    }

    const DeleteImage = async (image) => {
      try {
        const res = await axios.delete(`/MutilImg/delete/${image}`);
       console.log(res);
      return true;
      } catch (error) {
        console.error("Error updating:", error);
        if (error.response && error.response.name === 403 ) {
          message.error('Bạn không có quyền xóa danh mục này.');
        } else if (error.response && error.response.status === 400   || error.response.status === 500) {
          message.error('Vui lòng xóa sản phẩm trước.');
         } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
          message.error('Vui Lòng Đăng Nhập lại.');
         }
        throw error;
      }
    };

    const DeleteMutiImage = async (image) => {
      console.log("sdfjsdjbfjsdbfdks",image);
      try {
        const res = await axios.delete(`/MutilImg/deleteMutiImage`, {
          headers: {
              'Content-Type': 'application/json'
          },
          data: image
      });
       console.log(res);
      return true;
      } catch (error) {
        console.error("Error updating:", error);
        if (error.response && error.response.name === 403 ) {
          message.error('Bạn không có quyền xóa danh mục này.');
        } else if (error.response && error.response.status === 400   || error.response.status === 500) {
          message.error('Vui lòng xóa sản phẩm trước.');
         } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
          message.error('Vui Lòng Đăng Nhập lại.');
         }
        throw error;
      }
    };

    const HandleImageUploadBefore = async (files, info, uploadHandler) => {
      const file = files[0];
      const formData = new FormData();
      formData.append('files', file);
    
      try {
        const response = await axios.post(`http://localhost:5256/api/MutilImg/PostImage`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
    
        console.log('Server response:', response.data);
    
        if (response.data && response.data.fileNames && response.data.fileNames.length > 0) {
          const imageUrl = `http://localhost:5256/api/img/${response.data.fileNames[0]}`;
          console.log('Image URL:', imageUrl);
    
          // Sử dụng uploadHandler để chèn URL của ảnh vào nội dung của SunEditor
          uploadHandler({
            result: [{ url: imageUrl }] // Chèn URL của ảnh vào editor
          });
        } else {
          throw new Error('No file names returned from the server.');
        }
      } catch (error) {
        console.error('Image upload failed:', error);
        message.error('Failed to upload image. Please try again later.');
        uploadHandler({
          result: [{ error: 'Failed to upload image' }] // Trả về lỗi nếu upload thất bại
        });
      }
    
      // Trả về false để chặn việc chèn ảnh base64 vào SunEditor
      return false;
    };

    return {GetImagedist, getAllImage, CreateImage, DeleteImage, DeleteMutiImage, HandleImageUploadBefore};
}

export {ManageImage};

