import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomCard from "../components/mutiAbout/Customcard";
import Banner from "../components/child/banner";
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import "./New.css";
import { HelmetHead } from "../components";
import axios from "../data/custom/axios";

const NewCard= ({index, id, image, title, intro, date}) =>{
  const showImage = (url) => {
    return (`${axios.defaults.baseURL}/img/${url}`)
  }
  return(
  <div key={index} className="newCard fadeIn "  data-wow-delay={`${0.3 + index * 0.2}s`}>
  <div className="case-item position-relative overflow-hidden rounded mb-2">
    <img className="img-fluid" src={showImage(image)} alt={title} />
    <a className="case-overlay" href={`/tin_tuc/${id}`}>
      <h2 className="mb-3">
        {title}
      </h2>
    </a>
    <small>Tin KCL GROUP| {new Date(date).toLocaleDateString()}</small>
  </div>
</div>
  )
}

function News() {
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      if (categoryData && postData) {
        const newsCategory = categoryData.find(ce => ce.categoryName.toLowerCase() === "Tin Tức".toLowerCase());
        if (newsCategory) {
          const filteredPosts = postData.filter(po => po.categoryId === newsCategory.categoryId);
          setPosts(filteredPosts);
          console.log("Post Data:", filteredPosts);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const settings = {
    dots: true,
    // infinite: true,
    slidesToShow: 3,
    rows: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          rows:3,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };  

  if (loading) {
    return (
      <div className="load">
        <p className="loader"></p>
      </div>
    );
  }

  return (
    <>
      <HelmetHead name="Tin Tức" title="Tin Tức Về KCL" description="Tin Tức" />
      <Banner title="TIN TỨC" />
      <div className="container">
        <div className="slider-container">
          {posts.length > 0 ? (
            <Slider {...settings}>
              {posts.map((item, index) => (
                <div key={index}>
                 
                  {/* <CustomCard
                  key={index}
                    name={ item.title} 
                    title={item.introContent}
                    content={item.content}
                    link={`/tin_tuc/${item.postId}`}
                    img={item.imageUrl}
                    footer={item.updatedDate}
                  /> */}

                  <NewCard
                  key={index}
                  index={index}
                  id={item.postId}
                  title={item.title}
                  image={item.imageUrl}
                  intro={item.introContent}
                  date={item.postDate}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="no-posts">Không có tin tức nào.</div>
          )}
        </div>
      </div>
    </>
  );
}

export default News;