import axios from "../custom/axios";
import {message } from 'antd';

const CRUDNav = () => {
  const GetNav = async () => {
    try {
      const response = await axios.get(`/navigation`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching cates:", error);
       if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      return false;
    }
  };
  const CreateNav=async (formData) => {
    try {
      const res = await axios.post(`/navigation`,formData)
      console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating Nav:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền thêm danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };

    const UpdateNav = async (formData) => {
      try {
        const res = await axios.put(`/navigation/${formData.navId}`, formData);
        console.log("trả về",res.data);
        return true; 
      } catch (error) {
        console.error('Error updating Nav:', error);
        if (error.response && error.response.name === 403 ) {
          message.error('Bạn không có quyền cập nhật danh mục này.');
        } else if (error.response && error.response.status === 400   || error.response.status === 500) {
          message.error('Vui lòng xóa sản phẩm trước.');
         } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
          message.error('Vui Lòng Đăng Nhập lại.');
         }
        throw error; 
      }
    };
  const DeleteNavById = async (id) =>{
    try {
      const res = await axios.delete(`/navigation/${id}`);
      console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete Nav",error);
        if (error.response && error.response.name === 403 ) {
      message.error('Bạn không có quyền xóa danh mục này.');
    } else if (error.response && error.response.status === 400 || error.response.status === 500) {
      message.error('Vui lòng xóa sản phẩm trước.');
    }  else if  ( error.response && error.response.status === 402 || error.response.status === 401) {
      message.error('Vui Lòng Đăng Nhập lại.');
      
     }
      throw error;
    };
  }
  return { GetNav, UpdateNav, DeleteNavById, CreateNav};
}
export {CRUDNav};
