import React, { useState, useEffect,useRef } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDPdfs } from "../../data/Pdf";
import 'react-quill/dist/quill.snow.css'

const UpdatePdfModal = (props) => {
  const [pdfPreview, setpdfPreview] = useState(false);
  const { open, onClose, Pdf, onUpdateSuccess, Pdfcategory, isHistory} = props;
  const {UpdatePdf} = CRUDPdfs();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    console.log("Pdf categori", Pdfcategory);
  const now = new Date();
    if (Pdf) {
      console.log("Pdf edit", Pdf);
        form.setFieldsValue({
        Id: Pdf.PdfId,
        title: Pdf.title,
        IntroContent: Pdf.introContent,
        content: Pdf.content,
        pdfUrl: Pdf.pdfUrl,
        PdfDate: Pdf.PdfDate,
        authorName: Pdf.authorName,
        categoryId: Pdf.categoryId,
        updatedDate: now.toISOString().slice(0, 16),
        year: Pdf.year ,
        status: Pdf.status,
      });
    }
  }, [Pdf, form]);
 
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
      const formData = new FormData();
      formData.append('id', Pdf.PdfId);
      formData.append('title', values.title);
      formData.append('IntroContent', values.IntroContent);
      formData.append('content', values.content);
      const pdfFile = form.getFieldValue('pdf');
      if (pdfFile ) {
        if (pdfFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", pdfFile.size);
          return;
        }
        formData.append('pdf', pdfFile);
      }
      formData.append('pdfUrl', values.pdfUrl); // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('PdfDate', values.PdfDate);
      formData.append('authorName', values.authorName);
      formData.append('categoryId', values.categoryId);
      formData.append('updatedDate', values.updatedDate);
      formData.append('year', values.year !== undefined ? values.year : null);
      formData.append('status', values.status );
      console.log("id formdata", formData.get('Id'));
      for (let [key, value] of formData.entries()) {
        console.log("không biết viết gì", key, value);
      }
      console.log("nội dung trong formdata", [...formData]);
      await UpdatePdf(formData);
      message.success('Pdf Update successfully');
      setpdfPreview(null);
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error Update Pdf:', error);
      message.error('Failed to Update Pdf. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };

//Pdf img



  return (
   <Modal
       width="55%"
     title="Edit Pdf"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
       <Form form={form} layout="vertical">
       <Form.Item
          name="Id"
          label="Pdf ID"
          rules={[{ required: true, message: 'Please input the Pdf title!' }]}
        >
          <Input disabled/>
          </Form.Item>
        <Form.Item
          name="title"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the Pdf title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="IntroContent"
          label="Giới thiệu"
        >
          <Input.TextArea rows={9} />
        </Form.Item>
        <input 
        type="file" 
        accept="pdf/*" 
        multiple="true"
        onChange={(e) => {
        const file = e.target.files[0];
        form.setFieldsValue({ pdf: file });
        
        // Tạo URL để preview ảnh
        if (file) {
          const previewURL = URL.createObjectURL(file);
          setpdfPreview(previewURL);
        } else {
          setpdfPreview(null);
        }
      }} 
    />
    {pdfPreview && (
      <div style={{ marginTop: '10px' }}>
        <img 
          src={pdfPreview} 
          alt="Preview" 
          style={{ maxWidth: '200px', maxHeight: '200px' }} 
        />
      </div>
    )}
        <Form.Item
          name="pdfUrl"
          label="pdf URL"
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="PdfDate"
          label="Ngày đăng"
        >
          <Input type="datetime-local" />
        </Form.Item>
        <Form.Item
          name="authorName"
          label="Tác Giả"
          rules={[{ required: true, message: 'Please input the author name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="updatedDate"
          label="Ngày Cập Nhật"
        >
          <Input type="datetime-local" disabled />
        </Form.Item>

        <Form.Item
          name="status"
          label="Trạng Thái"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
        </Form.Item>
      </Form>

    </Modal>
  );
};

export default UpdatePdfModal;
