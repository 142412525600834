import React, { useState } from 'react';
import { Modal, message } from 'antd';
import { CRUDBanners } from "../../data/banner";
const DeleteBanner = ({ open, onClose, Banner, onUpdateSuccess }) => {
    const {DeleteBannerById} = CRUDBanners();
  const [confirmLoading, setConfirmLoading] = useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    try {
      await DeleteBannerById(Banner.bannerId);
      message.success('Banner deleted successfully');
      setConfirmLoading(false);
      onClose();
      onUpdateSuccess();
    } catch (error) {
    console.log("lỗi",error);
      message.error('Failed to delete Banner');
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title={`Delete Banner ${Banner?.bannerName}?`}
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>Are you sure you want to delete Banner "{Banner?.bannerName}"?</p>
    </Modal>
  );
};

export default DeleteBanner;
