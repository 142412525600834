import React, { useEffect, useState } from 'react';
import ShopCard from '../item/ShopCard.jsx'; 
import Banner from '../child/banner.jsx'; 
import "./hel.css";
import { CRUDProducts } from '../../data/products.js';
import { CRUDProductCategorys } from '../../data/productCategorys.js';
import bannerImg from "../../image/K_HEALTHTECH/banner.png";
import { HelmetHead } from "../index.js";

const Accessory = () => {
  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const [Product, setProduct] = useState([]);
  const [Category, setCategory] = useState(null);
  const [GetIntro, setIntro] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    console.log("ss", Category);
  }, [Category]);
  const fetchData = async () => {
    try {
      const categoryData = await GetProductCategorys();
      const productData = await GetProducts();

      if (categoryData) {
        const categoriFind = categoryData.find(ce => ce.id === 10);
        if (categoriFind) {
          setCategory(categoriFind.name);
          
          const filterProduct = productData.filter(po => po.categoryId === categoriFind.id);
          console.log("lsb", filterProduct);

          const findIntro = filterProduct.find(po => po.name && po.name.toLowerCase().includes("HOẠT ĐỘNG".toLowerCase()));
          console.log("intro:", findIntro);

          if (findIntro) {
            setProduct(filterProduct.filter(item => item.id !== findIntro.id));
            setIntro(findIntro);
          } else {
            setProduct(filterProduct);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  return (
    <>
      <HelmetHead name="thiet_bi" title={Category} description={Category} />
      <Banner title={Category} />
      <div className="container">
            <div className="about-us">
              <div className="row">
                <div className="block-our-business-title py-4">
                  <h2 className="font-weight-light">{GetIntro.name}</h2>
                  <p className="block-our-business-desc-title">
                    {GetIntro.introContent}
                    </p>
                </div>
          </div>
        </div>
     
      <div className="container-fluid bg-trasparent my-4 p-3" style={{ position: 'relative' }}>
                <div className="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
        {Product.length > 0 ? (
          Product.map((item, index) => (
            <ShopCard
              key={index}
              index={index}
              name={item.name}
              title={item.introContent}
              content={item.description}
              link={`bai_viet/${item.id}`}
              img={item.urlImage}
              retailprice={item.retailprice}
            />
          ))
        ) : (
          <div className="load">
            <p className="loader"></p>
          </div>
        )}
      </div>
      </div>
      </div>
    </>
  );
};

export default Accessory;
