import axios from "../custom/axios";
import {message } from 'antd';

const CRUDPostingCategories = () => {
  const GetPostingCategories = async () => {
    try {
      const response = await axios.get(`/PostingCategories`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      //  if ( error.response && error.response.status === 402 || error.response.status === 401) {
      //   message.error('Vui Lòng Đăng Nhập lại.');
      //  }
      return false;
    }
  };
  const GetPostingCategoriById = async (id) => {
    try {
      const response = await axios.get(`/PostingCategories/${id}`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      //  if ( error.response && error.response.status === 402 || error.response.status === 401) {
      //   message.error('Vui Lòng Đăng Nhập lại.');
      //  }
      return false;
    }
  };
  const CreatePostingCategorie=async (formData) => {
    try {
      const res = await axios.post(`/PostingCategories`,formData)
      console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền thêm danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };

    const UpdatePostingCategorie = async (formData) => {
      try {
        const res = await axios.put(`/PostingCategories/${formData.Id}`, formData);
        console.log("trả về",res.data);
        return true; 
      } catch (error) {
        console.error('Error updating category:', error);
        if (error.response && error.response.name === 403 ) {
          message.error('Bạn không có quyền cập nhật danh mục này.');
        } else if (error.response && error.response.status === 400   || error.response.status === 500) {
          message.error('Vui lòng xóa sản phẩm trước.');
         } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
          message.error('Vui Lòng Đăng Nhập lại.');
         }
        throw error; 
      }
    };
  const DeletePostingCategorieById = async (id) =>{
    try {
      const res = await axios.delete(`/PostingCategories/${id}`);
      console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete category",error);
        if (error.response && error.response.name === 403 ) {
      message.error('Bạn không có quyền xóa danh mục này.');
    } else if (error.response && error.response.status === 400 || error.response.status === 500) {
      message.error('Vui lòng xóa sản phẩm trước.');
    }  else if  ( error.response && error.response.status === 402 || error.response.status === 401) {
      message.error('Vui Lòng Đăng Nhập lại.');
      
     }
      throw error;
    };
  }
  return { GetPostingCategories,GetPostingCategoriById, UpdatePostingCategorie, DeletePostingCategorieById, CreatePostingCategorie};
}


export {CRUDPostingCategories};
