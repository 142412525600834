import axios from "./custom/axios";
const CRUDPosts = () => {
  const GetPosts = async () => {
    try {
      const response = await axios.get(`/posts`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      return false;
    }
  };
  const GetPostsById = async (Id) => {
    try {
      const response = await axios.get(`/posts/${Id}`);
      // console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categories:", error);
      return false;
    }
  };
  
  return { GetPosts, GetPostsById};
}


export {CRUDPosts};
