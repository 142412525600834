
import axios from '../custom/axios';

const ImagePreview = ({ image }) => {
  if (!image ) {
    return <p>Chưa Có Ảnh Được thêm</p>;
  }

  const imageUrl = `${axios.defaults.baseURL}/img/${image}`;
  return (
    <>
      <img
        style={{ alignItems: 'center', justifyContent: 'center' }}
        src={imageUrl}
        alt={image}
      />
    </>
  );  
};

export default ImagePreview;
