import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDNav } from "../../data/nav";

const { Option } = Select;

const UpdateNaModal = (props) => {
  const { open, onClose, nav, onUpdateSuccess } = props;
  const { UpdateNav } = CRUDNav();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
   
    if (nav) {
      form.setFieldsValue({
        navId: nav.navId,
        navTitle: nav.navTitle,
        navUrl: nav.navUrl,
      });
    }

  }, [nav, form]);
  
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
     
      const updateNav = {
         navId: nav.navId,  
        navTitle: values.navTitle,
        navUrl: values.navUrl,
      };
    
      console.log("updateData", updateNav.navId);
      const result = await UpdateNav(updateNav);
      console.log("result", updateNav.navId);
     
      if (result) {
        message.success('Category updated successfully');
        onUpdateSuccess();
        onClose();
      } else {
        message.error('Failed to update nav');
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
   <Modal
       width="55%"
     title="Update Posting Category"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
      <Form.Item
          name="navTitle"
          label="navTitle"
          rules={[{ message: 'Please input theNav name!' }]}
        >
          <Input className="uppercase-input" />
        </Form.Item>
        <Form.Item
          name="navUrl"
          label="navUrl"
        >
          <Input  />  
          </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateNaModal;