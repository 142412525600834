import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select, Button } from 'antd';
import { UserService} from "../../data/user";
import { MDBIcon } from 'mdb-react-ui-kit';

const { Option } = Select;
const CreateUserModal = (props) => {

  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, onUpdateSuccess, postcategory } = props;
  const { CreateUser } = UserService();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const now = new Date();
  useEffect(() => {
    form.setFieldsValue({
      postDate: now.toISOString().slice(0, 16), // Format the datetime-local value
      updatedDate: now.toISOString().slice(0, 16),
    });
  }, [now, form]);

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error('Please input the password!'));
    }
    const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(value)) {
      return Promise.reject(new Error('Mật khẩu tối thiểu 8 kí tự, có 1 chứ cái viết hoa, 1 số, và 1 kí tự đặc biệt@ .'));
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Mật khẩu xác nhận không chính xác!'));
    },
  });

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('username', values.username);
      formData.append('email', values.email);
      formData.append('password', values.password);
      
      console.log(formData.get("username"))
      await CreateUser(formData);
      message.success('User created successfully');
      form.resetFields(); // Reset fields after successful submission
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error creating post:', error);
      message.error('Failed to create post. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };
  return (
   <Modal
       width="55%"
     title="Create new post"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
      <Form.Item
          name="username"
          label="User Name"
          rules={[{ required: true, message: 'Please input the post email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Please input the post email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[{ required: true, message: 'Please input the post password!' },
          { validator: validatePassword }
          ]}
        >
          <Input 
          type={showPassword ? "text" : "password"} 
          suffix={
            <MDBIcon 
              icon={showPassword ? "eye-slash" : "eye"} 
              onClick={() => setShowPassword(!showPassword)}
              style={{ cursor: 'pointer' }}
            />
          }
        />
        </Form.Item>
        <Form.Item
          name="confirmPassword"
          label="xác nhận mật khẩu"
          rules={[
            { required: true, message: 'Please confirm your password!' },
            validateConfirmPassword(form)
          ]}
          >
              <Input 
          type={showConfirmPassword ? "text" : "password"} 
          suffix={
            <MDBIcon 
              icon={showConfirmPassword ? "eye-slash" : "eye"} 
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              style={{ cursor: 'pointer' }}
            />
          }
        />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default CreateUserModal;
