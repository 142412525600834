import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDNavlist } from "../../data/navlist";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateNavlistModal from "./Create";
import UpdateNavlistModal from "./Update";
import DeleteNavlist from "./Delete";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";



const Navlists = () => {
  const { GetNavlist } = CRUDNavlist();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [navlist, setNavlist] = useState(null);
  const [categories, setNavlists] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const navlistData = await GetNavlist();
    if (navlistData) {
      setNavlists(navlistData);
    }
  };

  const showModalEditNavlist = (row) => {
    console.log("Selected navlist for edit:", row);
    setNavlist(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setNavlist(null);
  };

  const showModalConfirmDeleteNavlist = (row) => {
    setNavlist(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateNavlist = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  
  const columns = [
    { field: "navlistId", headerName: "ID", flex: 0.3 },
    {
      field: "navlistTitle",
      headerName: "Navlistigation Name ",
      flex: 0.7,
      editable: true,
    },
    {
      field: "navlistUrl",
      headerName: "Đường Dẫn",
      flex: 1,
      editable: true,
    },


    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditNavlist(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteNavlist(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Navlists" subtitle="Managing the Navlists" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateNavlist}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.navlistId} // Specify categorieId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      )}
      <CreateNavlistModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        navlist={navlist}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <UpdateNavlistModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        navlist={navlist}
        onUpdateSuccess={handleUpdateSuccess}
      />
      <DeleteNavlist
        open={isDeleteModalOpen}
        onClose={handleModalCloseModalDelete}
        navlist={navlist}
        onUpdateSuccess={handleUpdateSuccess}
      />
    </Box>
  );
};

export default Navlists;
