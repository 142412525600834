import React, { useEffect, useState } from 'react';
import Contencard from '../item/contenCard.jsx'; // Đảm bảo đường dẫn đúng
import Banner from '../child/banner.jsx'; // Đảm bảo đường dẫn đúng
import "./hel.css";
import { CRUDProducts } from '../../data/products.js';
import { CRUDProductCategorys } from '../../data/productCategorys.js';
import bannerImg from "../../image/K_HEALTHTECH/banner.png";
import { HelmetHead } from "../index.js";

const K_HEALTHCARE = () => {
  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const [Product, setProduct] = useState([]);
  const [GetIntro, setIntro] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetProductCategorys();
      const productData = await GetProducts();
      if (categoryData) {
        const categoriFind = categoryData.find(ce => ce.name.toLowerCase() === "K HEALTHTECH".toLowerCase());
      if (categoriFind) {
       const filterProduct = productData.filter(po => po.categoryId === categoriFind.id);
       console.log("lsb", filterProduct)
       const findIntro = filterProduct.find(po => po.name && po.name.toLowerCase().includes("HOẠT ĐỘNG".toLowerCase())
      );
       console.log("intro:", findIntro);
         if (findIntro) {
          setProduct(filterProduct.filter(item => item.id !== findIntro.id));
         setIntro(findIntro);
         }
         else{
          setProduct(filterProduct)
         }
      }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

 
  return (
    <>
      <HelmetHead name="K_HEALTHTECH" title="Giới  Thiệu K HEALTHTECH" description="K_HEALTHTECH" />
      <Banner title="K HEALTHCARE" />
      <div className="container">
            <div className="about-us">
              <div className="row">
                <div className="block-our-business-title py-4">
                  <h2 className="font-weight-light">{GetIntro.name}</h2>
                  <p className="block-our-business-desc-title">
                    {GetIntro.introContent}
                    </p>
                </div>
          </div>
        </div>
      </div>
      <div className=" k-hel py-5">
        {Product.length > 0 ? (
          Product.map((item, index) => (
            <Contencard
              key={index}
              index={index}
              name={item.name}
              title={item.introContent}
              content={item.description}
              link={`bai_viet/${item.id}`}
              img={item.urlImage}
            />
          ))
        ) : (
          <div className="load">
            <p className="loader"></p>
          </div>
        )}
      </div>
    </>
  );
};

export default K_HEALTHCARE;
