import React from "react";
import img from "../../image/bg-hero.png";
import noImage from "../../image/600x300.png";
import axios from "../../data/custom/axios";
import "./style/conten.css"
const Contencard = ({ index, name, title, content, img, link }) => {
  const backgroundImageStyle = {
    backgroundImage: "url(../../image/bg-hero.png)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  let imageUrl = null;
  if (!img) {
    imageUrl = noImage;
  } else {
    imageUrl = `${axios.defaults.baseURL}/img/${img}`;
    console.log("img",imageUrl)
  }

  return (
    <div className={`container-fluid ${index % 2 === 0 ? 'bg-2' : 'bg-1'}`}>
      <div className={`container`}>
        <div className="row p-3">
          <div className={`col-lg-6 ${index % 2 === 0 ? 'order-lg-2' : ''}`}>
            <h2 className="font-weight-light title">{name}</h2>
            <p>{title}</p>
            {content && <a href={link} className="contenbtn btn btn-orange">Xem thêm</a>}
          </div>
          <div className="col-lg-6" style={backgroundImageStyle}>
            <img
              className="img-thumbnail rounded mb-4 mb-lg-0"
              src={imageUrl}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contencard;
