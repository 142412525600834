import { badgeClasses } from '@mui/material';
import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
// import axios from 'axios';

const ColorPicker = () => {
  const [color, setColor] = useState('#fff');
  const [name, setName] = useState('');
  const [colorCode, setColorCode] = useState('');

  const handleChangeComplete = (selectedColor) => {
    setColor(selectedColor.hex);
  };

  const handleSubmit = async () => {
    const colorData = {
      name: name,
      colorCode: colorCode || color,
    };

//     try {
//       const response = await axios.post('URL_TO_YOUR_API', colorData);
//       console.log('Color saved successfully:', response.data);
//     } catch (error) {
//       console.error('Error saving color:', error);
//     }
  };

  return (
    <div>
      <input
      style={{backgroundColor : "white"}}
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Color Name"
      />
      <input
        type="text"
        value={colorCode}
        onChange={(e) => setColorCode(e.target.value)}
        placeholder="Color Code (optional)"
      />
      <SketchPicker color={color} onChangeComplete={handleChangeComplete} />
      <button onClick={handleSubmit}>Save Color</button>
      <div style={{ marginTop: '20px' }}>
        <div style={{ backgroundColor: "white", width: '100px', height: '100px' }}></div>
        <p>Selected Color: {color}</p>
      </div>
    </div>
  );
};

export default ColorPicker;
