import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDPostingCategories } from "../../data/postingCategories";

const { Option } = Select;

const UpdatePostingCategorieModal = (props) => {
  const { open, onClose, category, onUpdateSuccess } = props;
  const { UpdatePostingCategorie } = CRUDPostingCategories();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
   
    if (category) {
      form.setFieldsValue({
        categoryId: category.categoryId,
        categoryName: category.categoryName,
        createdTime: category.createdTime,
        updatedTime: category.updatedTime,
        status: category.status
      });
    }

  }, [category, form]);
  
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
     
      const updatedCategory = {
        Id: values.categoryId,  
        CategoryName: values.categoryName,
        createdTime: values.createdTime,
        updatedTime:  values.updatedTime,
        Status: values.status
      };
      console.log("updateData", updatedCategory.Id);
      const result = await UpdatePostingCategorie(updatedCategory);
     
      if (result) {
        message.success('Category updated successfully');
        onUpdateSuccess();
        onClose();
      } else {
        message.error('Failed to update category');
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
   <Modal
       width="55%"
     title="Update Posting Category"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
      <Form.Item
          name="categoryId"
          label="Category ID"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
 <Input disabled />
        </Form.Item>
        <Form.Item
          name="categoryName"
          label="Category Name"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="createdTime"
          label="createdTime"
          rules={[{  message: 'Please input the category name!' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="updatedTime"
          label="updatedTime"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
          <Input type = "datetime-local" />
        </Form.Item>
        
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
            <Option value="0">active</Option>
            <Option value="1">inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdatePostingCategorieModal;