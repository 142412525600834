import React, { useEffect, useState } from 'react';
import Contencard from '../item/contenCard.jsx';
import Banner from '../child/banner.jsx';
import { CRUDProducts } from '../../data/products.js';
import { CRUDProductCategorys } from '../../data/productCategorys.js';
import { HelmetHead } from "../index.js";

function K_TECHNICAL() {
  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const [Product, setProduct] = useState([]);
  const [GetIntro, setIntro] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetProductCategorys();
      const productData = await GetProducts();
      
      if (categoryData && productData) {
        const categoriFind = categoryData.find(ce => ce.name.toLowerCase() === "K TECHNICAL".toLowerCase());
        
        if (categoriFind) {
          const filterProduct = productData.filter(po => po.categoryId === categoriFind.id);
          const findIntro = filterProduct.find(po => po.name && po.name.toLowerCase().includes("HOẠT ĐỘNG".toLowerCase()));
          if (findIntro) {
            setProduct(filterProduct.filter(item => item.id !== findIntro.id));
            setIntro(findIntro);
            console.log("intro:", findIntro); 
          }
          else{
            setProduct(filterProduct)
           }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!Product || !GetIntro) {
    return (
      <div className="load">
        <p className="loader"></p>
      </div>
    );
  }

  return (
    <>
      <HelmetHead name="K_TECHNICAL" title="Giới Thiệu K TECHNICAL" description="K TECHNICAL" />
      <Banner title="K TECHNICAL" />
      <div className="container">
            <div className="about-us">
              <div className="row">
                <div className="block-our-business-title py-4">
                {GetIntro && (
                    <>
                      <h2 className="font-weight-light">{GetIntro.name}</h2>
                      <p className="block-our-business-desc-title">
                        {GetIntro.introContent}
                      </p>
                    </>
                  )}
                </div>
              </div>
        </div>
      </div>
      <div className="k-hel py-5">
        {Product.length > 0 ? (
          Product.map((item, index) => (
            <Contencard
              key={index}
              index={index}
              name={item.name}
              title={item.introContent}
              content={item.description}
              link={`bai_viet/${item.id}`}
              img={item.urlImage}
            />
          ))
        ) : (
          <div className="load">
            <p className="loader"></p>
          </div>
        )}
      </div>
    </>
  );
}

export default K_TECHNICAL;
