import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { SketchPicker } from 'react-color';
import { CRUDCol } from "../../data/color";

const { Option } = Select;

const UpdateNaModal = (colors) => {
  const { open, onClose, Col, onUpdateSuccess } = colors;
  const { UpdateCol } = CRUDCol();
  const [form] = Form.useForm();
  const [color, setColor] = useState('#FFFFFF');
  const [confirmLoading, setConfirmLoading] = useState(false);
 
  useEffect(() => {
    if (Col) {
      form.setFieldsValue({
        id: Col.id,
        colorName: Col.colorName,
        
      });
      setColor(Col.colorCode);
    }

  }, [Col, form]);
  
  const handleColorChange = (color) => {
    setColor(color.hex);
    // Update color state with the selected color
  };
  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);
     
      const updateCol = {
         id: Col.id,  
        colorName: values.colorName,
        colorCode: color,
      };
    
      console.log("updateData", updateCol.id);
      const result = await UpdateCol(updateCol);
      console.log("result", updateCol.ColId);
     
      if (result) {
        message.success('Category updated successfully');
        onUpdateSuccess();
        onClose();
      } else {
        message.error('Failed to update Col');
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
   <Modal
       width="55%"
     title="Update Posting Category"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
     <Form form={form} layout="vertical">
        <Form.Item
          className="uppercase-input"
          name="colorName"
          label="Color Name"
          
        >
          <Input className="uppercase-input" disabled/>
        </Form.Item>
        <Form.Item
          name="codeColor"
          label={color}
        >
          <Input value={color} readOnly style={{backgroundColor: color}} />  
        </Form.Item>
        <Form.Item
          label="Select Color"
        >
          <SketchPicker
            color={color}
            onChangeComplete={handleColorChange}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateNaModal;