import React from "react";
import { Link } from "react-router-dom";
import axios from "../../data/custom/axios";
import DOMPurify from "dompurify";
const Chill_SanPham = ({img, title, subtitle}) => {
  if (!img ) {
    return <p>Chưa Có Ảnh Được thêm</p>;
  }
  const imageUrl = `${axios.defaults.baseURL}/img/${img}`;
  return (
    <div className="home">
      <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 img-bg">
              <img
                className="img-fluid rounded mb-4 mb-lg-0"
                src={imageUrl}
                alt=""
              />
            </div>
            <div className="col-lg-6">
              <h2 className="font-weight-light" style={{paddingBottom : "20px"}}>{title}</h2>
              <div   dangerouslySetInnerHTML={{ __html: subtitle }}>
              </div>
            </div>
          </div>
      </div>
      </div>
  )}

export default Chill_SanPham;
