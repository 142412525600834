import React, { useEffect, useState } from 'react';
import './banner.css'
import axios from "../../data/custom/axios";
import noImage from "../../image/UndefileBanner.png"
import {CRUDBanners} from "../../data/banner"
const Banner = ({title}) =>{
  const {GetBanners} = CRUDBanners();
  const [Get1Banner, setBanners] = useState([]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [title]);

  const fetchData = async () => {
    const BannerData = await GetBanners();
   
    if (BannerData && title) {
      const GetImgBanner = BannerData.find(banner => banner.bannerName.toLowerCase() === title.toLowerCase());
   
      if (GetImgBanner) {
        setBanners(GetImgBanner);
      }
    }
  };

  let imageUrl = null;
    if (!Get1Banner.imageData ) {
       imageUrl = `${noImage}`;
      }
      else {  
        imageUrl= `${axios.defaults.baseURL}/img/${Get1Banner.imageData}`;
      }
    return(
        <div className="banner">
           <img src={imageUrl} alt="banner" />
           <div className="banner-title col-sm-12 col-md-12 col-lg-7">
           <h1>{title}</h1>
           </div>
       </div>
    )
};  

export default Banner;