import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDProductCategorys } from "../../data/productCategorys";

const { Option } = Select;

const UpdateProductCategorieModal = (props) => {
  const { open, onClose, category, onUpdateSuccess } = props;
  const { UpdateProductCategory} = CRUDProductCategorys();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
   
    if (category) {
      console.log("prodcut category", category.name);
    
      form.setFieldsValue({
        Id: category.id,
        Name: category.name,
        createdTime: category.createdTime,
        updatedTime: category.updatedTime,
        status: category.status,
      });
    }

  }, [category, form]);
  
  const handleOk = async () => {
  
     try {
     
      const values = await form.validateFields();
      setConfirmLoading(true);
     console.log("values",values);
      const updatedProduct = {
        id: values.Id,  
        name: values.Name,
        createdTime: values.createdTime,
        updatedTime:  values.updatedTime,
        Status: values.Status
      }; 
      const result = UpdateProductCategory(updatedProduct);
      console.log("handleOk");
      if (result) {
        message.success('Product updated successfully');
        onUpdateSuccess();
        onClose();
      } else {
        message.error('Failed to update product category');
      }
    } catch (error) {
      console.error('Validation failed:', error);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
   <Modal
       width="55%"
     title="Update Product Product"
      open={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={onClose}
    >
      <Form form={form} layout="vertical">
      <Form.Item
          name="Id"
          label="Product ID"
          rules={[{ required: true, message: 'Please input the product category name!' }]}
        >
 <Input disabled />
        </Form.Item>
        <Form.Item
          name="Name"
          label="Tên danh mục"
          rules={[{ required: true, message: 'Please input the product category name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="createdTime"
          label="Ngày tạo"
          rules={[{  message: 'Please input the product category name!' }]}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="updatedTime"
          label="Ngày sửa"
          rules={[{ required: true, message: 'Please input the product category name!' }]}
        >
          <Input type = "datetime-local" />
        </Form.Item>
        
        <Form.Item
         name="status"
         label="Trạng thái"
          rules={[{ required: true, message: 'Please select the status!' }]}
          initialValue={category?.status}
        >
          <Select>
            <Option value={0}>active</Option>
            <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateProductCategorieModal;