import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import { CRUDProducts  } from "../../data/products";
import axios from "axios";

import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const { Option } = Select;
const UpdateproductModal = (props) => {
  const [imagePreview, setImagePreview] = useState(false);
  const { open, onClose, Product, onUpdateSuccess, Productcategory } = props;
  const {UpdateProduct} = CRUDProducts();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [content, setContent] = useState('');

  const [editorContent, setEditorContent] = useState(form.getFieldValue('content'));

  
  useEffect(() => {
    console.log("product categori", Productcategory);
  const now = new Date();
    if (Product) {
      console.log("product edit", Product);
        form.setFieldsValue({
        Id: Product.id,
        name: Product.name,
        content: Product.description,
        IntroContent: Product.introContent,
        urlImage: Product.urlImage,
        image: Product.image,
        productDate: Product.addedTime,
        authorName: Product.authorName,
        categoryId: Product.categoryId,
        marketprice: Product.marketprice,
        retailprice: Product.retailprice,
        updatedDate: now.toISOString().slice(0, 16),
        status: Product.status,
      });
      const timer = setTimeout(() => {
        setEditorContent(Product.description); // Cập nhật nội dung của SunEditor sau 1 giây
      }, 500);
      return () => clearTimeout(timer)
    }
  }, [Product, form]);
 
  const handleEditorChange = (content) => {
    form.setFieldsValue({ content }); // Cập nhật giá trị trong form khi nội dung thay đổi
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      const formData = new FormData();
      formData.append('Id', Product.id);
      formData.append('Name', values.name);
      formData.append('introContent', values.IntroContent);
      formData.append('description', values.content);
      formData.append('imageUrl', Product.urlImage)
      const imageFile = form.getFieldValue('image');
      if (imageFile) { 
if (imageFile.size > 10 * 1024 * 1024) {
          message.error("Dung lượng ảnh quá lớn, vui lòng chọn ảnh có dung lượng dưới 10MB");
          console.log("dung lượng ảnh quá lớn", imageFile.size);
          return;
        }
        formData.append('Image', imageFile);
      }

      // Nếu bạn sử dụng Upload component để upload ảnh
      formData.append('productDate', values.addedTime);
      formData.append('authorName', values.authorName);
      formData.append('categoryId', values.categoryId);
      formData.append('updatedDate', values.updatedTime);
      formData.append('status', values.status);
      formData.append('urlImage', Product.urlImage)
      if (values.retailprice !== undefined && values.retailprice !== null) {
        formData.append('retailprice', values.retailprice);
      }
     
      if (values.marketprice !== undefined && values.marketprice !== null) {
        formData.append('marketprice', values.marketprice);
      }
      console.log("id formdata", formData.get('Id'));
      for (let [key, value] of formData.entries()) {
        console.log("không biết viết gì", key, value);
      }
      console.log("nội dung trong formdata", [...formData]);
      await UpdateProduct(formData);
      message.success('product Update successfully');
      form.resetFields(); // Reset fields after successful submission
      setConfirmLoading(false);
      setContent('');
      onClose();
      onUpdateSuccess();
      
    } catch (error) {
      console.error('Error Update product:', error);
      message.error('Failed to Update Product. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };

  //upload image
  const handleImageUploadBefore = async (files, info, uploadHandler) => {
    const file = files[0];
    const formData = new FormData();
    formData.append('files', file);
  
    try {
      const response = await axios.post(`http://localhost:5256/api/MutilImg/PostImage`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Server response:', response.data);
  
      if (response.data && response.data.fileNames && response.data.fileNames.length > 0) {
        const imageUrl = `http://localhost:5256/api/img/${response.data.fileNames[0]}`;
        console.log('Image URL:', imageUrl);
  
        // Sử dụng uploadHandler để chèn URL của ảnh vào nội dung của SunEditor
        uploadHandler({
          result: [{ url: imageUrl }] // Chèn URL của ảnh vào editor
        });
      } else {
        throw new Error('No file names returned from the server.');
      }
    } catch (error) {
      console.error('Image upload failed:', error);
      message.error('Failed to upload image. Please try again later.');
      uploadHandler({
        result: [{ error: 'Failed to upload image' }] // Trả về lỗi nếu upload thất bại
      });
    }
  
    // Trả về false để chặn việc chèn ảnh base64 vào SunEditor
    return false;
  };


  //end upload img qill

  const handleCancel = () => {
    form.resetFields();
    setContent(''); // Xóa nội dung của SunEditor khi nhấn Cancel
    //form.setFieldsValue('');
    onClose();
  };
  return (
   <Modal
       width="55%"
     title="Edit product"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel} 
    >
       <Form form={form} layout="vertical">
       <Form.Item
          name="Id"
          label="product ID"
          rules={[{ required: true, message: 'Please input the product title!' }]}
        >
          <Input disabled/>
          </Form.Item>
        <Form.Item
          name="name"
          label="Tiêu đề"
          rules={[{ required: true, message: 'Please input the product title!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="IntroContent" 
          label="Giới thiệu"
        >
          <Input.TextArea rows={9} />
        </Form.Item>
        <Form.Item
          name="content"
          label="Nội dung"
        >
            <SunEditor
            setContents={form.getFieldValue('content')}
            onImageUploadBefore={handleImageUploadBefore} // Chặn hành vi mặc định trước khi upload
            onChange={handleEditorChange}
            setOptions={{
              buttonList: [
                ['undo', 'redo'],
                ['bold', 'underline', 'italic', 'strike'],
                ['list', 'indent', 'outdent'],
                ['align', 'fontSize', 'formatBlock'],
                ['image', 'link'],
                ['removeFormat']
              ],
            }}
          />
        </Form.Item>
        <input 
      type="file" 
      accept="image/*" 
      onChange={(e) => {
        const file = e.target.files[0];
        form.setFieldsValue({ image: file });
        
        // Tạo URL để preview ảnh
        if (file) {
          const previewURL = URL.createObjectURL(file);
          setImagePreview(previewURL);
        } else {
          setImagePreview(null);
        }
      }} 
    />
    {imagePreview && (
      <div style={{ marginTop: '10px' }}>
        <img 
          src={imagePreview} 
          alt="Preview" 
          style={{ maxWidth: '200px', maxHeight: '200px' }} 
        />
      </div>
    )}
        <Form.Item
          name="urlImage"
          label="Image URL"
        >
          <Input disabled/>
        </Form.Item>
        <Form.Item
          name="productDate"
          label="product Date"
        >
          <Input type="datetime-local" />
        </Form.Item>
          <Form.Item
          name="categoryId"
          label="Loại Bài Viết"
          rules={[{ required: true, message: 'Please select the category ID!' }]}
        >
          <Select>
            {Productcategory && Productcategory.length > 0 ? (
              Productcategory.map(category => (
                <Option key={category.id} value={category.id}>
                  {category.name}
                </Option>
              ))
            ) : (
              <Option value="" disabled>
                No categories available
              </Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          name="updatedDate"
          label="Updated Date"
        >
          <Input type="datetime-local" disabled />
        </Form.Item>
        <Form.Item
          name="retailprice"
          label="Giá Bán"
        >
          <Input.TextArea/>
          </Form.Item>
          <Form.Item
          name="marketprice"
          label="Giá Thị Trường"
        >
          <Input.TextArea/>
          </Form.Item>
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please input the status!' }]}
        >
           <Select>
          <Option value={0}>active</Option>
          <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateproductModal;
