import React, { useEffect, useState } from 'react';
import "./style/tamnhin.css";
import image from "../../image/banner2.jpg";
import { CRUDPosts } from "../../data/posts";
import { CRUDPostingCategories } from "../../data/postingCategories";
import RocketIcon from '@mui/icons-material/RocketLaunch';
import GroupsIcon from '@mui/icons-material/Groups';
import WorkIcon from '@mui/icons-material/Work';
import MemoryIcon from '@mui/icons-material/Memory';


const icon = [
  < GroupsIcon />,
  < RocketIcon />,
  < MemoryIcon />,
]

const TamNhin = () => {
  console.log("icon", icon)
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [GetIntro, SetIntro] = useState(null);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetPostingCategories();
      const postData = await GetPosts();
      if (categoryData && postData) {
        const newsCategory = categoryData.find(ce => ce.categoryName.toLowerCase() === "TẦM NHÌN CHIẾN LƯỢC".toLowerCase());
        console.log("News Category:", newsCategory);
        if (newsCategory) {
          const filteredPosts = postData.filter(po => po.categoryId === newsCategory.categoryId);
          const findIntro = filteredPosts.find(po => po.title && po.title.toLowerCase().includes("INTRO".toLowerCase()));
          if (findIntro) {
            const postsWithIcons = filteredPosts.map((post, index) => ({
              ...post,
              icon: icon[index % icon.length]
            }));
            setPosts(postsWithIcons.filter(po => po.title !== findIntro.title));
            console.log("fildData:", findIntro);
            console.log("Post Data:", posts);
            SetIntro(findIntro);
          }
          else {
            setPosts(filteredPosts);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className="container-fluid">
      <div className="container TamNhin" >
        <div className="row"  >
          <div className="col-lg-4">
            <h2 className="mb-4">TẦM NHÌN CHIẾN LƯỢC</h2>
            <div className="conten">
              <p>
                {GetIntro && GetIntro.introContent}
              </p>
            </div>
            <a className="btn btn-orange" href="/ve_kcl/tam_nhin_chien_luoc">Xem Thêm</a>
          </div>

          <div className="col-lg-8">
            <div className="row">

              {posts && posts.map((item, index) => (
                <div className="col-md-4 col-sm-12 " key={index}>
                  <div className="card custom-card-vision ">
                    <div className="card-body custom-body">
                      <i className='custom-icon'>{item.icon}</i>
                      <h5 className="card-title">{item.title}</h5>
                      <p className="card-text text-vision">{item.introContent}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="mision_bottom">
        <img src={image} alt="img" className="img-fluid" />
      </div>
    </div>
  )
}
export default TamNhin;