import axios from "./custom/axios";
const CRUDProducts = () => {
  const GetProducts = async () => {
    try {
      const response = await axios.get(`/Products`);
      console.log("ksdjbfk", response)
      return response;
    } catch (error) {
      console.error("Error during fetching categories:", error);
      return false;
    }
  };

  const GetProductById = async (Id ) => {
    try {
      const response = await axios.get(`/Products/${Id}`)
      return response;
    } catch (error) {
      console.error('Error updating Product:', error);
      throw error; 
    }
  };
  return { GetProducts, GetProductById};
}


export {CRUDProducts};
