import axios from "../custom/axios";
import {message} from "antd";
 const CRUDProductCategorys = () => {
  const GetProductCategorys = async () => {
    try {
      const response = await axios.get(`/ProductCategory`);
      console.log(response);
      return response;
    
    } catch (error) {
      console.error("Error during fetching categorys:", error);
      if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      return false;
    }
  };
  const CreateProductCategory=async (formData) => {
    try {
      const res = await axios.post(`/ProductCategory`,formData)
      console.log(res);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      throw error; 
    }
  };

  const UpdateProductCategory = async (formData) => {
    try {
      const res = await axios.put(`/ProductCategory/${formData.id}`, formData);
      console.log(res.data);
      return true; 
    } catch (error) {
      console.error('Error updating category:', error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền cập nhật danh mục này.');
      } else if (error.response && error.response.status === 400   || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 401 || error.response.status === 402) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
      throw error; 
    }
  };
  const DeleteProductCategoryById = async (id) =>{
    try {
      const res = await axios.delete(`/ProductCategory/${id}`);
      console.log(res);
      return true;
    } catch (error) {
      console.error("Error delete category",error);
      if (error.response && error.response.name === 403 ) {
        message.error('Bạn không có quyền xóa danh mục này.');
      } else if (error.response && error.response.status === 400 || error.response.status === 500) {
        message.error('Vui lòng xóa sản phẩm trước.');
       } else if ( error.response && error.response.status === 402 || error.response.status === 401) {
        message.error('Vui Lòng Đăng Nhập lại.');
       }
     
      throw error;
    };
  }
  return { GetProductCategorys, UpdateProductCategory, DeleteProductCategoryById, CreateProductCategory};
}


export {CRUDProductCategorys};
