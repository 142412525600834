import React, { useState, useEffect } from 'react';
import { Button, Modal, Input, Form, message } from 'antd';
import {UserService} from "../../data/user";

const UpdateUser = ({ open, onClose, user, onUpdateSuccess }) => {
  const [confirmLoading, setConfirmLoading] = useState(false);
  const { UpdateUser } = UserService();
  const [form] = Form.useForm();

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        id: user.id,
        name: user.name,
        email: user.email,
        isAdmin: user.isAdmin,
        phone_number: user.phone_number,
      });
    }
  }, [user, form]);

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      setConfirmLoading(true);

      // Construct the user data object
      const userData = {
        userId: values.id,
        fullName: values.name,
        email: values.email,
        phoneNumber: values.phone_number,
        password: values.password, // Assuming you have a password field in the form
        isAdmin: values.isAdmin,
        avata: null // Assuming you handle image uploading separately
      };

      console.log('Data to send:', userData);
      
      // Update user information using axios
      await UpdateUser(values.id, userData);

      message.success('User updated successfully');
      onClose();
      onUpdateSuccess();
    } catch (error) {
      console.error('Error updating user:', error);
      message.error('Failed to update user. Please try again later.');
    } finally {
      setConfirmLoading(false);
    }
  };

  const handleCancel = () => {
    onClose();
  };

  return (
   <Modal
       width="55%"
     title="Edit User"
      visible={open}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="id"
          label="User ID"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: 'Please input the user name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Please input the user email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone_number"
          label="Phone Number"
          rules={[{ required: true, message: 'Please input the user phone number!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input the password!' }]}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default UpdateUser;
