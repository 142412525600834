import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import { CRUDCol } from "../../data/color";
import { IconButton, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import CreateColModal from "./Create";
import UpdateColModal from "./Update";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import "./color.css"



const ColorCell = ({ value }) => {
  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: '50%',
        backgroundColor: value,
        display: 'inline-block',
        margin: '0 auto',
        border: '1px solid #ccc', // Optional: add a border to the circle
      }}
    />
  );
};

const Cols = () => {
  const { GetCol } = CRUDCol();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [Col, setCol] = useState(null);
  const [categories, setCols] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = async () => {
    const ColData = await GetCol();
    if (ColData) {
      setCols(ColData);
    }
  };

  const showModalEditCol = (row) => {
    setCol(row);
    setIsModalOpen(true);
  };

  const handleModalCloseModalEdit = () => {
    setIsModalOpen(false);
    setCol(null);
  };

  const showModalConfirmDeleteCol = (row) => {
    setCol(row);
    setIsDeleteModalOpen(true);
  };

  const handleUpdateSuccess = () => {
    fetchData();
  };

  const handleModalCloseModalDelete = () => {
    setIsDeleteModalOpen(false);
  };

  const showModalCreateCol = () => {
    setIsCreateModalOpen(true);
  };

  const handleModalCloseModalCreate = () => {
    setIsCreateModalOpen(false);
  };

  
  const columns = [
  
    {
      field: "colorName",
      headerName: "Loại",
      flex: 1,
      editable: true,
    },
    {
      field: "colorCode",
      headerName: "Màu sắc",
      flex: 1,
      editable: true,
      renderCell: (params) => <ColorCell value={params.value} />,
    },
    {
      headerName: "Action",
      renderCell: ({ row }) => (
        <Box display="flex" justifyContent="center">
          <IconButton
            color={colors.grey[500]}
            onClick={() => showModalEditCol(row)}
          >
            <EditNoteOutlinedIcon />
          </IconButton>
          <IconButton
            color={colors.redAccent[500]}
            onClick={() => showModalConfirmDeleteCol(row)}
          >
            <DeleteOutlinedIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box m="20px"
    width="40%"
    >
      <Header title="Cols" subtitle="Managing the Cols" />
      <IconButton
        color={colors.redAccent[500]}
        onClick={showModalCreateCol}
      >
        <CreateNewFolderIcon />
      </IconButton>
      {categories.length > 0 && (
        <DataGrid
          rows={categories}
          columns={columns}
          getRowId={(row) => row.id} // Specify categorieId as the row ID
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 10 },
            },
          }}
          pageSizeOptions={[5, 10]}
        />
      )}
      <CreateColModal
        open={isCreateModalOpen}
        onClose={handleModalCloseModalCreate}
        Col = {Col}
        onUpdateSuccess={handleUpdateSuccess}
      />

      <UpdateColModal
        open={isEditModalOpen}
        onClose={handleModalCloseModalEdit}
        Col={Col}
        onUpdateSuccess={handleUpdateSuccess}
      />

    </Box>
  );
};

export default Cols;
