import React, { useState, useEffect } from "react";
import { Modal, Input, Form, message, Select } from 'antd';
import {CRUDPostingCategories} from "../../data/postingCategories";
import { times } from "lodash";
const { Option } = Select;

const CreatePostingCategorieModal = (props) => {
  const { open, onClose, onUpdateSuccess } = props;
  const {CreatePostingCategorie} = CRUDPostingCategories();
  const [form] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  // const handleOk = async ()  => {
  //   try {
  //       const values = await form.validateFields();
  //       console.log(confirmLoading);
  //       setConfirmLoading(true);
  //       const formData = new FormData();
  //       formData.append('kind', values.kind);
  //       await CreatePostingCategorie(formData);
  //       message.success('PostingCategorie create successfully');
  //       form.resetFields(); // Reset fields after successful submission
  //       onClose();
  //       onUpdateSuccess(); 
  //     } catch (error) {
  //       console.error('Error updating category:', error);
  //       message.error('Failed to update category. Please try again later.');
  //     } finally {
  //       setConfirmLoading(false);
  //     }
  //   };
  const now = new Date();
  useEffect(() => {
    form.setFieldsValue({
      createdTime: now.toISOString().slice(0, 16), // Format the datetime-local value
      updatedTime: now.toISOString().slice(0, 16),
      status: 0,
    });
  }, [now, form]);

    const handleOk = async () => {
      try {
        const values = await form.validateFields();
        setConfirmLoading(true);
       
        const AddCategory = {
          // Id: values.categoryId,  
          CategoryName: values.categoryName,
          createdTime: values.createdTime,
          updatedTime:  values.updatedTime,
          Status: values.status
        };
        console.log("AddCategory", AddCategory);
        
        const result = await CreatePostingCategorie(AddCategory);
       
        if (result) {
          message.success('Category create successfully');
          onUpdateSuccess();
          onClose();
        } else {
          message.error('Failed to create category');
        }
      } catch (error) {
        console.error('Validation failed:', error);
      } finally {
        setConfirmLoading(false);
      }
    }
  

  return (
   <Modal
       width="55%"
     title="Create new category"
      visible={open}
      onOk={handleOk}
      onCancel={onClose}
    >
     <Form form={form} layout="vertical">
      {/* <Form.Item
          name="categoryId"
          label="Category ID"
          rules={[{ required: true, message: 'Please input the category name!' }]}
        >
 <Input disabled />
        </Form.Item> */}
        <Form.Item
          name="categoryName"
          label="Category Name"
          rules={[{ message: 'Please input the category name!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="createdTime"
          label="createdTime"
          rules={[{ message: 'Please input   the category name!' }]}
        >
          <Input  type="datetime-local" />  
        </Form.Item>
        <Form.Item
          name="updatedTime"
          label="updatedTime"
         
        >
          <Input  disabled/>
        </Form.Item>
        
        <Form.Item
          name="status"
          label="Status"
          rules={[{ required: true, message: 'Please select the status!' }]}
        >
          <Select>
          <Option value={0}>active</Option>
          <Option value={1}>inactive  </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreatePostingCategorieModal;
