import React, { useEffect, useState } from 'react';
import "./CoreValues.css";
import Banner from "../components/child/banner";
import { CRUDPosts } from "../data/posts";
import { CRUDPostingCategories } from "../data/postingCategories";
import axios from "../data/custom/axios";
import { HelmetHead } from "../components";

function Corevalues() {
  const { GetPosts } = CRUDPosts();
  const { GetPostingCategories } = CRUDPostingCategories();
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const categoryData = await GetPostingCategories();
    const postData = await GetPosts();
    if (categoryData) {
      const categoriHistori = categoryData.find(ce => ce.categoryName.toLowerCase() === "GIÁ TRỊ CỐT LÕI".toLowerCase());
      if (categoriHistori) {
        const filterPost = postData.filter(po => po.categoryId === categoriHistori.categoryId);
        if (filterPost) {
          setPosts(filterPost);
          console.log("Post Data:", filterPost);
        }
      }
    }
  };
  const showImage = (url) => {
    return (`${axios.defaults.baseURL}/img/${url}`)
  }
  const findPostByTitle = (title) => posts.find(post => post.title.toLowerCase() === title.toLowerCase());

  return (
    <>
      <HelmetHead name="GIÁ TRỊ CỐT LÕI" title="GIÁ TRỊ CỐT LÕI KCL GROUP" description="GIÁ TRỊ" />
      <Banner title="GIÁ TRỊ CỐT LÕI" />
      <div className="corevalues">
        <div className="header bg-2">
          <h2>GIÁ TRỊ CỐT LÕI CỦA CHÚNG TÔI </h2>
          <p>{findPostByTitle("GIÁ TRỊ CỐT LÕI")?.content}</p>
        </div>
        <div className="row1-container">
          <div className="box box-down cyan">
            <h2>Lễ</h2>
            <p>{findPostByTitle("Lễ")?.content}</p>
            <img src={ showImage(findPostByTitle("Lễ")?.imageUrl)} alt="Lễ" />
          </div>
          <div className="box red">
            <h2>NHÂN </h2>
            <p>{findPostByTitle("NHÂN")?.content}</p>
            <img src={ showImage(findPostByTitle("NHÂN")?.imageUrl)} alt="NHÂN" />
          </div>
          <div className="box box-down blue">
            <h2>Nghĩa</h2>
            <p>{findPostByTitle("Nghĩa")?.content}</p>
            <img src={ showImage(findPostByTitle("Nghĩa")?.imageUrl)} alt="Nghĩa" />
          </div>
          <div className="hexagon"/>
        </div>
        <div className="row2-container">
          <div className="box orange">
            <h2>TRÍ</h2>
            <p>{findPostByTitle("TRÍ")?.content}</p>
            <img src={ showImage(findPostByTitle("TRÍ")?.imageUrl)} alt="TRÍ" />
          </div>
          <div className="box green">
            <h2>Tín</h2>
            <p>{findPostByTitle("Tín")?.content}</p>
            <img src={ showImage(findPostByTitle("Tín")?.imageUrl)} alt="Tín" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Corevalues;