import Intro from "../child/intro";
import K_SOLUTIONimg from "../../image/K-solutionIMG.png";
import K_HEALTHTECHimg from "../../image/K_HEALTHTECHimg.jpg";
import K_TECHNICALimg from "../../image/K_TECHNICALimg.jpg";
import React, { useEffect, useState } from 'react';
import { CRUDProducts } from '../../data/products.js';
import { CRUDProductCategorys } from '../../data/productCategorys.js';
import axios from "../../data/custom/axios";

const intro = [

]

const IntroAbout = () => {
  const { GetProducts } = CRUDProducts();
  const { GetProductCategorys } = CRUDProductCategorys();
  const [Product, setProduct] = useState([]);
  const [GetIntro, setIntro] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const categoryData = await GetProductCategorys();
      const productData = await GetProducts();
      if (categoryData) {
        const categoriFind = categoryData.find(ce => ce.name.toLowerCase() === "Home".toLowerCase());
      if (categoriFind) {
       const filterProduct = productData.filter(po => po.categoryId === categoriFind.id);
       const findIntro = filterProduct.find(po => po.name && po.name.toLowerCase().includes("intro".toLowerCase())
      );
       console.log("intro:", findIntro);
         if (findIntro) {
          setProduct(filterProduct.filter(item => item.id !== findIntro.id));
         setIntro(findIntro);
         }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  if (!Product) {
    return <div className="load">
    <p className="loader"></p>
  </div>;
  }
 


  
const showImage = (url) => {
  return (`${axios.defaults.baseURL}/img/${url}`)
}

  return (
    <>
      <div className="container-fluid my-5">
          <div className="container">
            <div className="about-us">
              <div className="row">
                <div className="block-our-business-title">
                  <h2 className="font-weight-light">HỆ SINH THÁI KCL</h2>
                  <p className="block-our-business-desc-title ">
                    {GetIntro && GetIntro.introContent}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-1">
            <div className="container mt-5">
              <div className="row mb-4">
                {Product && Product.map((item, index) => (
                  <Intro img={showImage(item.urlImage)} title={item.name} subtitle={item.introContent}
                    link={'/san_pham/' + `k_${item.name.toLowerCase()}`} key={index}
                  />
                ))}
              </div>
            </div>
          </div>
      </div>
    </>
  )
}

export default IntroAbout;