document.addEventListener('DOMContentLoaded', (event) => {
    const targetNode = document.getElementById('your-element-id');
    if (targetNode) {
        const callback = function(mutationsList, observer) {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    console.log('A child node has been added or removed.');
                    // Handle the DOM insertion event here
                }
            }
        };

        const observer = new MutationObserver(callback);
        const config = { childList: true, subtree: true };
        observer.observe(targetNode, config);
    } else {
        console.error('Target node not found.');
    }
});
