// import { Box } from "@mui/material"; 
import React from "react";

import { Outlet } from "react-router-dom"; // Import Outlet

import {
    Navigation,
    Footer,
} from "../components";

// const Layout = () => {
//     return (
//         <>
//             <Navigation />
//             <main >
//                 <Outlet />
//             </main>
//             <Footer className="footer" />
//         </>
//     );
// }

// AdminLayout.js
// const AdminLayout = ({ children }) => (
//     <div>
//       <Sidebar />
//       <main>{children}</main>
//     </div>
//   );
  
  // MainLayout.js
  const MainLayout = ({ children }) => (
    <div>
    <Navigation />
    <main>
      <Outlet />
    </main>
    <Footer />
  </div>
  );

export default MainLayout;
