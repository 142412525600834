import {
TamNhin,
Banner,
IntroAbout
} from "../../components";
function fields(){
    return(
        <div>
            <Banner title="LĨNH VỰC HOẠT ĐỘNG" />
<IntroAbout/>
        </div>
    )
}
export default fields;
